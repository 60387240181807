import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../../config.json";
const url = `${configData.ApiAddress}/bourse/fund`;

export const NAVHistoryAction = createAsyncThunk(
  "rest/NAVHistory",
  async (slug) => {
    return axios
      .get(`${url}/rest/NAVHistory/${slug.funds_slug}`)
      .then((response) => response.data);
  }
);

export const fundfinalPriceRecordAction = createAsyncThunk(
  "rest/fundfinalPriceRecord",
  async (slug) => {
    return axios
      .get(`${url}/rest/fundfinalPriceRecord/${slug.funds_slug}`)
      .then((response) => response.data);
  }
);

export const fundratioHistoryAction = createAsyncThunk(
  "rest/fundratioHistory",
  async (slug) => {
    return axios
      .get(`${url}/rest/fundratioHistory/${slug.funds_slug}`)
      .then((response) => response.data);
  }
);
