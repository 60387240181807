import React from "react";
import { Link } from "react-router-dom";
import {  Container, Nav, Navbar } from "react-bootstrap";
import configData from "../../redux/config.json";

const domain = `${configData.ApiAddress}/static`;

const StockHeader = ({ color }) => {
  if (color == "black") {
    var background = "linear-gradient(135deg, #121212 0%, #2D1F3D 100%)";
    var link = "nav1-link";
  } else if (color == "white") {
    var background = "linear-gradient(135deg, #121212 0%, #2D1F3D 100%)";
    var link = "nav1-link";
  }

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <Container
      className=""
      style={{
        transition: "all 4.3s ease-in-out",
        background: background,
        direction:"rtl"
      }}
      fluid
    >
      <Navbar
        style={{
          background: background,
          transition: "all 4.3s ease-in-out",
          height: "6vh",
        }}
        expand="lg"
        className="modern-navbar"
      >
        <Navbar.Brand className="me-4">
          <Link style={{ textDecoration: "none", color: "white" }} to="/">
            <img style={{ height: 40 }} src={`${domain}/images/logo-min.png`} />
          </Link>
        </Navbar.Brand>

        <Nav className="me-5">
          <Nav.Link
            style={{ transition: "all 0.3s ease-in-out" }}
            className={link}
            as={Link}
            to="/"
          >
            Home
          </Nav.Link>

          <Nav.Link
            style={{ transition: "all 0.3s ease-in-out" }}
            className={link}
            as={Link}
            to="/Commodities"
          >
            Commodities
          </Nav.Link>
        </Nav>

        <Navbar.Toggle aria-controls="navbarScroll" />

        <Navbar.Collapse id="navbarScroll">
          <Nav style={{ marginRight: "180px" }} navbarScroll></Nav>

          <Nav
            className="me-auto my-2 my-lg-0"
            navbarScroll
            style={{ paddingLeft: "20px" }}
          ></Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default StockHeader;
