import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../../config.json";
const url = `${configData.ApiAddress}/bourse/fund`;

//#region Bourse Main Page

export const FundBoxAction = createAsyncThunk("main/FundBox", async (slug) => {
  return axios
    .get(`${url}/main/FundBox/${slug.funds_slug}`)
    .then((response) => response.data);
});

export const FundTseBoxAction = createAsyncThunk(
  "main/FundTseBox",
  async (slug) => {
    return axios
      .get(`${url}/main/FundTseBox/${slug.funds_slug}`)
      .then((response) => response.data);
  }
);

export const FundChartAction = createAsyncThunk(
  "main/FundChart",
  async (slug) => {
    return axios
      .get(`${url}/main/FundChart/${slug.funds_slug}`)
      .then((response) => response.data);
  }
);

export const fundratioTableDailyTrackingAction = createAsyncThunk(
  "main/fundratioTableDailyTracking",
  async (slug) => {
    return axios
      .get(`${url}/main/fundratioTableDailyTracking/${slug.funds_slug}`)
      .then((response) => response.data);
  }
);

//#region Stock --> order Table
export const FundCompetitorsTableAction = createAsyncThunk(
  "main/FundCompetitorsTable",
  async (slug) => {
    return axios
      .get(`${url}/main/FundCompetitorsTable/${slug}`)
      .then((response) => response.data);
  }
);
//#endregion

//#region Stock --> order Table
export const fundorderTableAction = createAsyncThunk(
  "main/fundorderTable",
  async (slug) => {
    return axios
      .get(`${url}/main/fundorderTable/${slug.funds_slug}`)
      .then((response) => response.data);
  }
);

export const fundsliderAction = createAsyncThunk(
  "main/fundslider",
  async (slug) => {
    return axios
      .get(`${url}/main/fundslider/${slug.funds_slug}`)
      .then((response) => response.data);
  }
);
//#endregion



//#region Stock --> StockHolder Chart
export const fundHolderAction = createAsyncThunk("main/fundHolder", async (slug) => {
  return axios.get(`${url}/main/fundholder/${slug.funds_slug}`).then((response) => response.data);
});
//#endregion



//#region Stock --> ratio Table
export const fundratioTableAction = createAsyncThunk("main/fundratioTable", async (slug) => {
  return axios.get(`${url}/main/fundratiotable/${slug.funds_slug}`).then((response) => response.data);
});
//#endregion
