import { createSlice } from "@reduxjs/toolkit";
import {
  stockboxAction,
  tseboxAction,
  orderTableAction,
  CompetitorsTableAction,
  ratioTableAction,
  stockHolderAction,
  dailyPriceChartAction,
  ratioTableDailyTrackingAction,
  sidebarAction,
  sliderAction
} from "./bourseStockMainPageAction";

//#region Stockbox Component
export const stockboxSlice = createSlice({
  name: "stockboxData",
  initialState: {
    stockboxLoading: false,
    stockbox: [],
    stockboxError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch stockboxAction
    builder.addCase(stockboxAction.pending, (state, action) => {
      state.stockboxLoading = true;
    });
    builder.addCase(stockboxAction.fulfilled, (state, action) => {
      state.stockboxLoading = false;
      state.stockbox = action.payload;
      state.stockboxError = null;
    });
    builder.addCase(stockboxAction.rejected, (state, action) => {
      state.stockboxLoading = false;
      state.stockboxError = action.error.message;
    });
  },
});
//#endregion





export const tseboxSlice = createSlice({
  name: "tseboxData",
  initialState: {
    tseboxLoading: false,
    tsebox: [],
    tseboxError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch tseboxAction
    builder.addCase(tseboxAction.pending, (state, action) => {
      state.tseboxLoading = true;
    });
    builder.addCase(tseboxAction.fulfilled, (state, action) => {
      state.tseboxLoading = false;
      state.tsebox = action.payload;
      state.tseboxError = null;
    });
    builder.addCase(tseboxAction.rejected, (state, action) => {
      state.tseboxLoading = false;
      state.tseboxError = action.error.message;
    });
  },
});









//#region OrderTable Component
export const orderTableSlice = createSlice({
  name: "orderTableData",
  initialState: {
    orderTableLoading: false,
    orderTable: [],
    orderTableError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch orderTableAction
    builder.addCase(orderTableAction.pending, (state, action) => {
      state.orderTableLoading = true;
    });
    builder.addCase(orderTableAction.fulfilled, (state, action) => {
      state.orderTableLoading = false;
      state.orderTable = action.payload;
      state.orderTableError = null;
    });
    builder.addCase(orderTableAction.rejected, (state, action) => {
      state.orderTableLoading = false;
      state.orderTableError = action.error.message;
    });
  },
});
//#endregion










//#region CompetitorsTable Component
export const CompetitorsTableSlice = createSlice({
  name: "CompetitorsTableData",
  initialState: {
    CompetitorsTableLoading: false,
    CompetitorsTable: [],
    CompetitorsTableError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch CompetitorsTableAction
    builder.addCase(CompetitorsTableAction.pending, (state, action) => {
      state.CompetitorsTableLoading = true;
    });
    builder.addCase(CompetitorsTableAction.fulfilled, (state, action) => {
      state.CompetitorsTableLoading = false;
      state.CompetitorsTable = action.payload;
      state.CompetitorsTableError = null;
    });
    builder.addCase(CompetitorsTableAction.rejected, (state, action) => {
      state.CompetitorsTableLoading = false;
      state.CompetitorsTableError = action.error.message;
    });
  },
});
//#endregion



























//#region Ratio Table Component
export const ratioTableSlice = createSlice({
  name: "ratioTableData",
  initialState: {
    ratioTableLoading: false,
    ratioTable: [],
    ratioTableError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch ratioTableAction
    builder.addCase(ratioTableAction.pending, (state, action) => {
      state.ratioTableLoading = true;
    });
    builder.addCase(ratioTableAction.fulfilled, (state, action) => {
      state.ratioTableLoading = false;
      state.ratioTable = action.payload;
      state.ratioTableError = null;
    });
    builder.addCase(ratioTableAction.rejected, (state, action) => {
      state.ratioTableLoading = false;
      state.ratioTableError = action.error.message;
    });
  },
});
//#endregion

//#region stockHolder Chart
export const stockHolderSlice = createSlice({
  name: "stockHolderData",
  initialState: {
    stockHolderLoading: false,
    stockHolder: [],
    stockHolderError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch stockHolderAction
    builder.addCase(stockHolderAction.pending, (state, action) => {
      state.stockHolderLoading = true;
    });
    builder.addCase(stockHolderAction.fulfilled, (state, action) => {
      state.stockHolderLoading = false;
      state.stockHolder = action.payload;
      state.stockHolderError = null;
    });
    builder.addCase(stockHolderAction.rejected, (state, action) => {
      state.stockHolderLoading = false;
      state.stockHolderError = action.error.message;
    });
  },
});
//#endregion

//#region daily Price Chart
export const dailyPriceChartSlice = createSlice({
  name: "dailyPriceChartData",
  initialState: {
    dailyPriceChartLoading: false,
    dailyPriceChart: [],
    dailyPriceChartError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch dailyPriceChartAction
    builder.addCase(dailyPriceChartAction.pending, (state, action) => {
      state.dailyPriceChartLoading = true;
    });
    builder.addCase(dailyPriceChartAction.fulfilled, (state, action) => {
      state.dailyPriceChartLoading = false;
      state.dailyPriceChart = action.payload;
      state.dailyPriceChartError = null;
    });
    builder.addCase(dailyPriceChartAction.rejected, (state, action) => {
      state.dailyPriceChartLoading = false;
      state.dailyPriceChartError = action.error.message;
    });
  },
});
//#endregion




//#region ratio TableDailyTracking
export const ratioTableDailyTrackingSlice = createSlice({
  name: "ratioTableDailyTrackingData",
  initialState: {
    ratioTableDailyTrackingLoading: false,
    ratioTableDailyTracking: [],
    ratioTableDailyTrackingError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch ratioTableDailyTrackingAction
    builder.addCase(ratioTableDailyTrackingAction.pending, (state, action) => {
      state.ratioTableDailyTrackingLoading = true;
    });
    builder.addCase(ratioTableDailyTrackingAction.fulfilled, (state, action) => {
      state.ratioTableDailyTrackingLoading = false;
      state.ratioTableDailyTracking = action.payload;
      state.ratioTableDailyTrackingError = null;
    });
    builder.addCase(ratioTableDailyTrackingAction.rejected, (state, action) => {
      state.ratioTableDailyTrackingLoading = false;
      state.ratioTableDailyTrackingError = action.error.message;
    });
  },
});
//#endregion

















//#region ratio TableDailyTracking
export const sidebarSlice = createSlice({
  name: "sidebarData",
  initialState: {
    sidebarLoading: false,
    sidebar: [],
    sidebarError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch sidebarAction
    builder.addCase(sidebarAction.pending, (state, action) => {
      state.sidebarLoading = true;
    });
    builder.addCase(sidebarAction.fulfilled, (state, action) => {
      state.sidebarLoading = false;
      state.sidebar = action.payload;
      state.sidebarError = null;
    });
    builder.addCase(sidebarAction.rejected, (state, action) => {
      state.sidebarLoading = false;
      state.sidebarError = action.error.message;
    });
  },
});
//#endregion













//#region ratio TableDailyTracking
export const sliderSlice = createSlice({
  name: "sliderData",
  initialState: {
    sliderLoading: false,
    slider: [],
    sliderError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch sliderAction
    builder.addCase(sliderAction.pending, (state, action) => {
      state.sliderLoading = true;
    });
    builder.addCase(sliderAction.fulfilled, (state, action) => {
      state.sliderLoading = false;
      state.slider = action.payload;
      state.sliderError = null;
    });
    builder.addCase(sliderAction.rejected, (state, action) => {
      state.sliderLoading = false;
      state.sliderError = action.error.message;
    });
  },
});
//#endregion














