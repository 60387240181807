import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { logout } from "./userAccountReducer";
import configData from "../../config.json";
const url = `${configData.ApiAddress}/users`;

export const userInfoAction = createAsyncThunk("main/dailyindex", async () => {
  return axios.get(`${url}/main/dailyindex/`).then((response) => response.data);
});

//#region loginAction
export const loginAction = createAsyncThunk("users/login/", async (data) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  return axios
    .post(`${url}/login/`, data, config)
    .then((response) => response.data);
});
//#endregion

//#region logoutAction
export const logoutAction = () => (dispatch) => {
  //   localStorage.removeItem("userInfo");
  dispatch(logout());
};
//#endregion

//#region register Action
export const registerAction = createAsyncThunk(
  "users/register/",
  async (data) => {
    return axios
      .post(`${url}/register/`, data)
      .then((response) => response.data);
  }
);
//#endregion

//#region userProfile Action
export const userProfileAction = createAsyncThunk(
  "users/userProfile",
  async () => {
    return axios.get(`${url}/profile/`).then((response) => response.data);
  }
);
//#endregion

//#region updateUserProfile Action
export const updateUserProfileAction = createAsyncThunk(
  "users/profile/update/",
  async (data) => {
    return axios
      .put(`${url}/profile/update/`, data)
      .then((response) => response.data);
  }
);
//#endregion
