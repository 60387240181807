import { createSlice } from "@reduxjs/toolkit";
import {
  monthlyReportAction,
  seasonalReportAction,
  lastSeasonalReportAction,
  balanceReportAction,
  marginReportAction,
  assemblyReportAction,
  realstateReportAction,
  InvTurnoverAction,
  CommodityMavadAvalieAction,
  OtherIncomeAction
} from "./bourseStockFundamentalAction";

//#region monthlyReport
export const monthlyReportSlice = createSlice({
  name: "monthlyReportData",
  initialState: {
    monthlyReportLoading: false,
    monthlyReport: [],
    monthlyReportError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch monthlyReportAction
    builder.addCase(monthlyReportAction.pending, (state, action) => {
      state.monthlyReportLoading = true;
    });
    builder.addCase(monthlyReportAction.fulfilled, (state, action) => {
      state.monthlyReportLoading = false;
      state.monthlyReport = action.payload;
      state.monthlyReportError = null;
    });
    builder.addCase(monthlyReportAction.rejected, (state, action) => {
      state.monthlyReportLoading = false;
      state.monthlyReportError = action.error.message;
    });
  },
});
//#endregion

//#region seasonalReport
export const seasonalReportSlice = createSlice({
  name: "seasonalReportData",
  initialState: {
    seasonalReportLoading: false,
    seasonalReport: [],
    seasonalReportError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch seasonalReportAction
    builder.addCase(seasonalReportAction.pending, (state, action) => {
      state.seasonalReportLoading = true;
    });
    builder.addCase(seasonalReportAction.fulfilled, (state, action) => {
      state.seasonalReportLoading = false;
      state.seasonalReport = action.payload;
      state.seasonalReportError = null;
    });
    builder.addCase(seasonalReportAction.rejected, (state, action) => {
      state.seasonalReportLoading = false;
      state.seasonalReportError = action.error.message;
    });
  },
});
//#endregion







//#region seasonalReport
export const CommodityMavadAvalieSlice = createSlice({
  name: "CommodityMavadAvalieData",
  initialState: {
    CommodityMavadAvalieLoading: false,
    CommodityMavadAvalie: [],
    CommodityMavadAvalieError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch CommodityMavadAvalieAction
    builder.addCase(CommodityMavadAvalieAction.pending, (state, action) => {
      state.CommodityMavadAvalieLoading = true;
    });
    builder.addCase(CommodityMavadAvalieAction.fulfilled, (state, action) => {
      state.CommodityMavadAvalieLoading = false;
      state.CommodityMavadAvalie = action.payload;
      state.CommodityMavadAvalieError = null;
    });
    builder.addCase(CommodityMavadAvalieAction.rejected, (state, action) => {
      state.CommodityMavadAvalieLoading = false;
      state.CommodityMavadAvalieError = action.error.message;
    });
  },
});
//#endregion















//#region lastSeasonalReport
export const lastSeasonalReportSlice = createSlice({
  name: "lastSeasonalReportData",
  initialState: {
    lastSeasonalReportLoading: false,
    lastSeasonalReport: [],
    lastSeasonalReportError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch lastSeasonalReportAction
    builder.addCase(lastSeasonalReportAction.pending, (state, action) => {
      state.lastSeasonalReportLoading = true;
    });
    builder.addCase(lastSeasonalReportAction.fulfilled, (state, action) => {
      state.lastSeasonalReportLoading = false;
      state.lastSeasonalReport = action.payload;
      state.lastSeasonalReportError = null;
    });
    builder.addCase(lastSeasonalReportAction.rejected, (state, action) => {
      state.lastSeasonalReportLoading = false;
      state.lastSeasonalReportError = action.error.message;
    });
  },
});
//#endregion

//#region balanceReport
export const balanceReportSlice = createSlice({
  name: "balanceReportData",
  initialState: {
    balanceReportLoading: false,
    balanceReport: [],
    balanceReportError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch balanceReportAction
    builder.addCase(balanceReportAction.pending, (state, action) => {
      state.balanceReportLoading = true;
    });
    builder.addCase(balanceReportAction.fulfilled, (state, action) => {
      state.balanceReportLoading = false;
      state.balanceReport = action.payload;
      state.balanceReportError = null;
    });
    builder.addCase(balanceReportAction.rejected, (state, action) => {
      state.balanceReportLoading = false;
      state.balanceReportError = action.error.message;
    });
  },
});
//#endregion

//#region marginReport
export const marginReportSlice = createSlice({
  name: "marginReportData",
  initialState: {
    marginReportLoading: false,
    marginReport: [],
    marginReportError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch marginReportAction
    builder.addCase(marginReportAction.pending, (state, action) => {
      state.marginReportLoading = true;
    });
    builder.addCase(marginReportAction.fulfilled, (state, action) => {
      state.marginReportLoading = false;
      state.marginReport = action.payload;
      state.marginReportError = null;
    });
    builder.addCase(marginReportAction.rejected, (state, action) => {
      state.marginReportLoading = false;
      state.marginReportError = action.error.message;
    });
  },
});
//#endregion

//#region assemblyReport
export const assemblyReportSlice = createSlice({
  name: "assemblyReportData",
  initialState: {
    assemblyReportLoading: false,
    assemblyReport: [],
    assemblyReportError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch assemblyReportAction
    builder.addCase(assemblyReportAction.pending, (state, action) => {
      state.assemblyReportLoading = true;
    });
    builder.addCase(assemblyReportAction.fulfilled, (state, action) => {
      state.assemblyReportLoading = false;
      state.assemblyReport = action.payload;
      state.assemblyReportError = null;
    });
    builder.addCase(assemblyReportAction.rejected, (state, action) => {
      state.assemblyReportLoading = false;
      state.assemblyReportError = action.error.message;
    });
  },
});
//#endregion

//#region realstateReport
export const realstateReportSlice = createSlice({
  name: "realstateReportData",
  initialState: {
    realstateReportLoading: false,
    realstateReport: [],
    realstateReportError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch realstateReportAction
    builder.addCase(realstateReportAction.pending, (state, action) => {
      state.realstateReportLoading = true;
    });
    builder.addCase(realstateReportAction.fulfilled, (state, action) => {
      state.realstateReportLoading = false;
      state.realstateReport = action.payload;
      state.realstateReportError = null;
    });
    builder.addCase(realstateReportAction.rejected, (state, action) => {
      state.realstateReportLoading = false;
      state.realstateReportError = action.error.message;
    });
  },
});
//#endregion









//#region realstateReport
export const InvTurnoverSlice = createSlice({
  name: "InvTurnoverData",
  initialState: {
    InvTurnoverLoading: false,
    InvTurnover: [],
    InvTurnoverError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch InvTurnoverAction
    builder.addCase(InvTurnoverAction.pending, (state, action) => {
      state.InvTurnoverLoading = true;
    });
    builder.addCase(InvTurnoverAction.fulfilled, (state, action) => {
      state.InvTurnoverLoading = false;
      state.InvTurnover = action.payload;
      state.InvTurnoverError = null;
    });
    builder.addCase(InvTurnoverAction.rejected, (state, action) => {
      state.InvTurnoverLoading = false;
      state.InvTurnoverError = action.error.message;
    });
  },
});
//#endregion





//#region realstateReport
export const OtherIncomeSlice = createSlice({
  name: "OtherIncomeData",
  initialState: {
    OtherIncomeLoading: false,
    OtherIncome: [],
    OtherIncomeError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch OtherIncomeAction
    builder.addCase(OtherIncomeAction.pending, (state, action) => {
      state.OtherIncomeLoading = true;
    });
    builder.addCase(OtherIncomeAction.fulfilled, (state, action) => {
      state.OtherIncomeLoading = false;
      state.OtherIncome = action.payload;
      state.OtherIncomeError = null;
    });
    builder.addCase(OtherIncomeAction.rejected, (state, action) => {
      state.OtherIncomeLoading = false;
      state.OtherIncomeError = action.error.message;
    });
  },
});
//#endregion



