import { createSlice } from "@reduxjs/toolkit";
import {
  NAVHistoryAction,
  fundfinalPriceRecordAction,
  fundratioHistoryAction,
} from "./bourseFundRestAction";

//#region NAVHistory
export const NAVHistorySlice = createSlice({
  name: "NAVHistoryData",
  initialState: {
    NAVHistoryLoading: false,
    NAVHistory: [],
    NAVHistoryError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch NAVHistoryAction
    builder.addCase(NAVHistoryAction.pending, (state, action) => {
      state.NAVHistoryLoading = true;
    });
    builder.addCase(NAVHistoryAction.fulfilled, (state, action) => {
      state.NAVHistoryLoading = false;
      state.NAVHistory = action.payload;
      state.NAVHistoryError = null;
    });
    builder.addCase(NAVHistoryAction.rejected, (state, action) => {
      state.NAVHistoryLoading = false;
      state.NAVHistoryError = action.error.message;
    });
  },
});
//#endregion

//#region NAVHistory
export const fundfinalPriceRecordSlice = createSlice({
  name: "fundfinalPriceRecordData",
  initialState: {
    fundfinalPriceRecordLoading: false,
    fundfinalPriceRecord: [],
    fundfinalPriceRecordError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch fundfinalPriceRecordAction
    builder.addCase(fundfinalPriceRecordAction.pending, (state, action) => {
      state.fundfinalPriceRecordLoading = true;
    });
    builder.addCase(fundfinalPriceRecordAction.fulfilled, (state, action) => {
      state.fundfinalPriceRecordLoading = false;
      state.fundfinalPriceRecord = action.payload;
      state.fundfinalPriceRecordError = null;
    });
    builder.addCase(fundfinalPriceRecordAction.rejected, (state, action) => {
      state.fundfinalPriceRecordLoading = false;
      state.fundfinalPriceRecordError = action.error.message;
    });
  },
});
//#endregion

//#region NAVHistory
export const fundratioHistorySlice = createSlice({
  name: "fundratioHistoryData",
  initialState: {
    fundratioHistoryLoading: false,
    fundratioHistory: [],
    fundratioHistoryError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch fundratioHistoryAction
    builder.addCase(fundratioHistoryAction.pending, (state, action) => {
      state.fundratioHistoryLoading = true;
    });
    builder.addCase(fundratioHistoryAction.fulfilled, (state, action) => {
      state.fundratioHistoryLoading = false;
      state.fundratioHistory = action.payload;
      state.fundratioHistoryError = null;
    });
    builder.addCase(fundratioHistoryAction.rejected, (state, action) => {
      state.fundratioHistoryLoading = false;
      state.fundratioHistoryError = action.error.message;
    });
  },
});
//#endregion
