import React, { useState, useEffect } from "react";
import { HashRouter, Routes, Route, Outlet } from "react-router-dom";

// => main page
import Home from "./pages/mainPages/Home.jsx";
import StockHeader from "./components/common/StockHeader";
import Sidebar from "./components/stockcomponents/sidebar/Sidebar";

import "./utils/global_css.css";

import configData from "./redux/config.json";

import Commodities from "./pages/mainPages/Commodities.jsx";
import Commodity from "./pages/mainPages/Commodity.jsx";

import Bollinger_Bands from "./pages/mainPages/Bollinger_Bands.js";
import Aroon from "./pages/mainPages/Aroon.js";
import Volatility from "./pages/mainPages/Volatility.js";
import Ichimoku from "./pages/mainPages/Ichimoku.js";
import Parabolic_SAR from "./pages/mainPages/Parabolic_SAR.js";
import Fibonacci from "./pages/mainPages/Fibonacci.js";
import Stochastic_oscillator from "./pages/mainPages/Stochastic_oscillator.js";
import Standard_deviation from "./pages/mainPages/Standard_deviation.js";
import RSI from "./pages/mainPages/RSI.js";
import EMA from "./pages/mainPages/EMA.js";
import MACD from "./pages/mainPages/MACD.js";
import MFI from "./pages/mainPages/MFI.js";

const static_url = `${configData.ApiAddress}/static`;

const StockPageSide = () => {
  var color = "black";
  return (
    <div style={{ display: "flex" }}>
      <Sidebar color={color} />
      <Outlet />
    </div>
  );
};

const StockPageHeader = ({ color }) => {
  return (
    <div style={{ direction: "rtl" }}>
      <StockHeader color={color} />
      <Outlet />
    </div>
  );
};

function App() {
  var domain = static_url;

  var theme = localStorage.getItem("theme");

  var color = "black";
  var scrollbarThumb_scrollbar = "#3a5a7c";
  var scrollbarTrack_scrollbar = "#e1e5eb";

  useEffect(() => {}, [theme]);

  // var zoom_level = 100 / Math.round(window.devicePixelRatio * 100);

  return (
    // <HashRouter>
    <div data-bs-theme="dark">
      <style>
        {`
      /* Global scrollbar styles */
      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-track {
        background: ${scrollbarTrack_scrollbar};
      }
      ::-webkit-scrollbar-thumb {
        background: ${scrollbarThumb_scrollbar};
      }

      /* For Firefox */
      * {
        scrollbar-width: thin;
        scrollbar-color: ${scrollbarThumb_scrollbar} ${scrollbarTrack_scrollbar};
      }

        overflow: auto;
      }
      `}
      </style>

      <Routes>
        <Route element={<StockPageHeader domain={domain} color={color} />}>
          <Route path="/" element={<Home domain={domain} color={color} />} />
          <Route
            path="/Commodities"
            element={<Commodities domain={domain} color={color} />}
          />

          <Route element={<StockPageSide />}>
            {/* => stock page */}

            <Route
              path="/Commodities/:Commodities_slug"
              element={<Commodity domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/Bollinger_Bands"
              element={<Bollinger_Bands domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/Aroon"
              element={<Aroon domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/Volatility"
              element={<Volatility domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/Ichimoku"
              element={<Ichimoku domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/Parabolic_SAR"
              element={<Parabolic_SAR domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/Fibonacci"
              element={<Fibonacci domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/Stochastic_oscillator"
              element={<Stochastic_oscillator domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/Standard_deviation"
              element={<Standard_deviation domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/RSI"
              element={<RSI domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/EMA"
              element={<EMA domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/MACD"
              element={<MACD domain={domain} color={color} />}
            />
            <Route
              path="/Commodities/:Commodities_slug/MFI"
              element={<MFI domain={domain} color={color} />}
            />
          </Route>
        </Route>
      </Routes>
    </div>
    // </HashRouter>
  );
}

export default App;
