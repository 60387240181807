import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../../config.json";
const url = `${configData.ApiAddress}/bourse/stock`;

//#region Stock -->
export const PriceActionAction = createAsyncThunk("ai/price_action", async (slug) => {
  return axios.get(`${url}/ai/price_action/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

//#region Stock -->
export const ConvergenceDivergenceAction = createAsyncThunk("ai/ConvergenceDivergence", async (data) => {
  return axios
    .get(`${url}/ai/ConvergenceDivergence/`, {
      params: {
        slug: data.slug.stocks_slug,
        indicator: data.indicator,
      },
    })
    .then((response) => response.data);
});
//#endregion

//#region Stock -->
export const ChartPatternAction = createAsyncThunk("ai/ChartPattern", async (slug) => {
  return axios.get(`${url}/ai/ChartPattern/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

//#region Stock -->
export const OHLCAction = createAsyncThunk("ai/OHLC", async (slug) => {
  return axios.get(`${url}/ai/OHLC/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion



//#region Stock -->
export const IndicatorAction = createAsyncThunk("ai/Indicator", async (slug) => {
  return axios.get(`${url}/ai/Indicator/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion




