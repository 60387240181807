// ThemeProvider.js
import React, { useState, useEffect } from "react";

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light"); // Default to light theme

  useEffect(() => {
    // Load user preference from local storage (if available)
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <div className={`app ${theme}-theme`}>
      {/* <button onClick={toggleTheme}>Toggle Theme</button> */}
      {children}
    </div>
  );
};

export default ThemeProvider;
