import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../config.json";
const url = `${configData.ApiAddress}/bourse`;

//#region Bourse Main Page

export const MarketTrackingAction = createAsyncThunk(
  "main/MarketTracking",
  async () => {
    return axios
      .get(`${url}/main/MarketTracking/`)
      .then((response) => response.data);
  }
);

export const dailyIndexAction = createAsyncThunk(
  "main/dailyindex",
  async () => {
    return axios
      .get(`${url}/main/dailyindex/`)
      .then((response) => response.data);
  }
);



export const boursemainimeAction = createAsyncThunk(
  "main/boursemainime",
  async () => {
    return axios
      .get(`${url}/main/boursemainime/`)
      .then((response) => response.data);
  }
);



export const line_trackingAction = createAsyncThunk(
  "main/line_tracking",
  async () => {
    return axios
      .get(`${url}/main/line_tracking/`)
      .then((response) => response.data);
  }
);


















export const smartMoneyAction = createAsyncThunk(
  "main/smartmoney",
  async () => {
    return axios
      .get(`${url}/main/smartmoney/`)
      .then((response) => response.data);
  }
);
//#endregion

//#region Stocks Groups Page
export const stocksGroupsAction = createAsyncThunk(
  "main/stocksgroup",
  async () => {
    return axios
      .get(`${url}/main/stocksgroup`)
      .then((response) => response.data);
  }
);
//#endregion




//#region Stocks Groups Page
export const ImeTodayAction = createAsyncThunk(
  "main/ImeToday",
  async () => {
    return axios
      .get(`${url}/main/ImeToday`)
      .then((response) => response.data);
  }
);
//#endregion





//#region Stocks Groups Page
export const GoldGroupAction = createAsyncThunk(
  "main/GoldGroup",
  async () => {
    return axios
      .get(`${url}/main/GoldGroup`)
      .then((response) => response.data);
  }
);
//#endregion


//#region Stocks Groups Page
export const GoldFundAction = createAsyncThunk(
  "main/GoldFund",
  async () => {
    return axios
      .get(`${url}/main/GoldFund`)
      .then((response) => response.data);
  }
);
//#endregion


//#region Stocks Groups Page
export const ArzAction = createAsyncThunk(
  "main/Arz",
  async () => {
    return axios
      .get(`${url}/main/Arz`)
      .then((response) => response.data);
  }
);
//#endregion





//#region Stocks Groups Page
export const goldrestAction = createAsyncThunk(
  "main/goldrest",
  async () => {
    return axios
      .get(`${url}/main/goldrest`)
      .then((response) => response.data);
  }
);
//#endregion









//#region Stocks Groups Page
export const BubbleMapAction = createAsyncThunk(
  "main/BubbleMap",
  async () => {
    return axios
      .get(`${url}/main/BubbleMap`)
      .then((response) => response.data);
  }
);
//#endregion


//#region Stocks Groups Page
export const HaghAction = createAsyncThunk(
  "main/Hagh",
  async () => {
    return axios
      .get(`${url}/main/Hagh`)
      .then((response) => response.data);
  }
);
//#endregion






//#region Stocks Groups Page
export const indextrackingAction = createAsyncThunk(
  "main/indextracking",
  async () => {
    return axios
      .get(`${url}/main/indextracking`)
      .then((response) => response.data);
  }
);
//#endregion














//#region Stocks Groups Page
export const goldcoinbourseAction = createAsyncThunk(
  "main/goldcoinbourse",
  async () => {
    return axios
      .get(`${url}/main/goldcoinbourse`)
      .then((response) => response.data);
  }
);
//#endregion





//#region Stocks List Page
export const GoldCurrencyAction = createAsyncThunk(
  "main/GoldCurrency",
  async (slug) => {
    return axios
      .get(`${url}/main/GoldCurrency/${slug}`)
      .then((response) => response.data);
  }
);
//#endregion




//#region Stocks List Page
export const GoldCurrencyCommodityParagraphAction = createAsyncThunk(
  "main/GoldCurrencyCommodityParagraph",
  async (slug) => {
    return axios
      .get(`${url}/main/GoldCurrencyCommodityParagraph/${slug}`)
      .then((response) => response.data);
  }
);
//#endregion
















//#region Stocks Groups Page
export const goldcoinazadAction = createAsyncThunk(
  "main/goldcoinazad",
  async () => {
    return axios
      .get(`${url}/main/goldcoinazad`)
      .then((response) => response.data);
  }
);
//#endregion









//#region Stocks List Page
export const ImeTalarAction = createAsyncThunk(
  "main/ImeTalar",
  async (slug) => {
    return axios
      .get(`${url}/main/ImeTalar/${slug}`)
      .then((response) => response.data);
  }
);
//#endregion


export const ImeCommodityAction = createAsyncThunk(
  "main/ImeCommodity/",
  async (data) => {
    return axios
      .get(`${url}/main/ImeCommodity/`, {
        params: {
          // company_name: data.company_name,
          commodity_id: data.commodity_id,
        },
      })
      .then((response) => response.data);
  }
);


export const OtherImeCommodityAction = createAsyncThunk(
  "main/OtherImeCommodity/",
  async (data) => {
    return axios
      .get(`${url}/main/OtherImeCommodity/`, {
        params: {
          commodity: data.commodity,
        },
      })
      .then((response) => response.data);
  }
);











export const ComparisonAction = createAsyncThunk(
  "main/Comparison/",
  async (data) => {
    // Convert the data object into URL parameters
    const params = Object.entries(data)
      .filter(([_, value]) => value !== undefined && value !== null)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    return axios
      .get(`${url}/main/Comparison/`, { params })
      .then((response) => response.data);
  }
);


















//#region Stocks List Page
export const MonthlyComparisonnamesAction = createAsyncThunk(
  "main/MonthlyComparisonnames",
  async () => {
    return axios
      .get(`${url}/main/MonthlyComparisonnames/`)
      .then((response) => response.data);
  }
);
//#endregion



export const MonthlyComparisonAction = createAsyncThunk(
  "main/MonthlyComparison/",
  async (data) => {
    return axios
      .get(`${url}/main/MonthlyComparison/`, {
        params: {
          item1: data.item1,
          item2: data.item2,
          item3: data.item3,
          item4: data.item4,
          item5: data.item5,
          item6: data.item6,
        },
      })
      .then((response) => response.data);
  }
);

















//#region Stocks Groups Page
export const FundsGroupsAction = createAsyncThunk(
  "main/FundsGroups",
  async () => {
    return axios
      .get(`${url}/main/FundsGroups`)
      .then((response) => response.data);
  }
);
//#endregion







//#region allstocksAction Page
export const allStocksAction = createAsyncThunk("main/allstocks", async () => {
  return axios.get(`${url}/main/allstocks/`).then((response) => response.data);
});
//#endregion





//#region allstocksAction Page
export const allFundAction = createAsyncThunk("main/allFund", async () => {
  return axios.get(`${url}/main/allFund/`).then((response) => response.data);
});
//#endregion





















//#region Stocks List Page
export const stocksListAction = createAsyncThunk(
  "main/stockslist",
  async (slug) => {
    return axios
      .get(`${url}/main/stocksgroup/${slug}`)
      .then((response) => response.data);
  }
);
//#endregion















//#region Stocks List Page
export const fundsListAction = createAsyncThunk(
  "main/fundslist",
  async (slug) => {
    return axios
      .get(`${url}/main/fundsgroup/${slug}`)
      .then((response) => response.data);
  }
);
//#endregion






//#region Top Toolbar
export const commoditiesAction = createAsyncThunk(
  "main/commodities",
  async () => {
    return axios
      .get(`${url}/main/commodities/`)
      .then((response) => response.data);
  }
);




//#region Stocks List Page
export const singlecommoAction = createAsyncThunk(
  "main/commodities",
  async (slug) => {
    return axios
      .get(`${url}/main/commodities/${slug.Commodities_slug}`)
      .then((response) => response.data);
  }
);
//#endregion






export const MarketMAction = createAsyncThunk("main/MarketM/", async () => {
  return axios
    .get(`${url}/main/MarketM/`)
    .then((response) => response.data);
});
//#endregion


export const MarketMFAction = createAsyncThunk("main/MarketMF/", async () => {
  return axios
    .get(`${url}/main/MarketMF/`)
    .then((response) => response.data);
});
//#endregion
















export const marketMapAction = createAsyncThunk("main/marketMap/", async () => {
  return axios
    .get(`${url}/main/MarketMapDaily/`)
    .then((response) => response.data);
});
//#endregion

export const CartAction = createAsyncThunk("main/Cart/", async (data) => {
  return axios
    .get(`${url}/main/Cart/`, {
      params: {
        email: data.info.email,
      },
    })
    .then((response) => response.data);
});

export const AddToCartAction = createAsyncThunk(
  "main/AddToCart/",
  async (data) => {
    return axios
      .get(`${url}/main/AddToCart/`, {
        params: {
          email: data.info.email,
          stock: data.stock,
        },
      })
      .then((response) => response.data);
  }
);

export const deletefromcartAction = createAsyncThunk(
  "main/deletefromcart/",
  async (data) => {
    return axios
      .get(`${url}/main/deletefromcart/`, {
        params: {
          email: data.info.email,
          stock: data.stock,
        },
      })
      .then((response) => response.data);
  }
);

export const FilterAction = createAsyncThunk("main/filter/", async (data) => {
  return axios
    .get(`${url}/main/filter/`, {
      params: {
        // name: data.name,
      },
    })
    .then((response) => response.data);
});

export const StockHolderFilterAction = createAsyncThunk(
  "main/StockHolderFilter/",
  async (data) => {
    return axios
      .get(`${url}/main/StockHolderFilter/`, {
        params: {
          name: data.name,
        },
      })
      .then((response) => response.data);
  }
);

export const AssemblyFilterAction = createAsyncThunk(
  "main/AssemblyFilter/",
  async (data) => {
    return axios
      .get(
        `${url}/main/AssemblyFilter/`,

        {
          params: {
            year: data.year,
          },
        }
      )
      .then((response) => response.data);
  }
);

export const MonthlyFilterAction = createAsyncThunk(
  "main/MonthlyFilter/",
  async (data) => {
    return axios
      .get(`${url}/main/MonthlyFilter/`, {
        params: {
          year: data.year,
        },
      })
      .then((response) => response.data);
  }
);

export const MarginFilterAction = createAsyncThunk(
  "main/MarginFilter/",
  async (data) => {
    return axios
      .get(`${url}/main/MarginFilter/`, {
        params: {
          season: data.season,
          year: data.year,
        },
      })
      .then((response) => response.data);
  }
);

//#region Stock --> getCorrelation
export const PEPSFilterAction = createAsyncThunk(
  "main/PEPS",
  async (result) => {
    return axios
      .get(`${url}/main/PEPS/`, {
        params: { data: result },
      })
      .then((response) => response.data);
  }
);
//#endregion

export const PriceActionFilterAction = createAsyncThunk(
  "main/PriceAction/",
  async () => {
    return axios
      .get(`${url}/main/PriceAction/`, {})
      .then((response) => response.data);
  }
);

export const ChartPatternsFilterAction = createAsyncThunk(
  "main/ChartPatterns/",
  async () => {
    return axios
      .get(`${url}/main/ChartPatterns/`, {})
      .then((response) => response.data);
  }
);

export const MonthReportAction = createAsyncThunk(
  "main/MonthReport",
  async (data) => {
    return axios
      .get(`${url}/main/MonthReport/`, {
        params: { year: data.year },
      })
      .then((response) => response.data);
  }
);

export const MonthReport1Action = createAsyncThunk(
  "main/MonthReport1",
  async (data) => {
    return axios
      .get(`${url}/main/MonthReport1/`, {
        params: { year: data.year, month: data.month },
      })
      .then((response) => response.data);
  }
);

export const UserThemeAction = createAsyncThunk(
  "main/UserTheme/",
  async (data) => {
    return axios
      .get(`${url}/main/UserTheme/`, {
        params: {
          number: data.info.mobile,
        },
      })

      .then((response) => response.data);
  }
);








//#region Stocks Groups Page
export const DaramadSabetTrackingAction = createAsyncThunk(
  "main/DaramadSabetTracking",
  async () => {
    return axios
      .get(`${url}/main/DaramadSabetTracking`)
      .then((response) => response.data);
  }
);
//#endregion
















export const ComingSoonPageAction = createAsyncThunk(
  "main/ComingSoonPage/",



  async (data) => {

    console.log()
    return axios
      .get(`${url}/main/ComingSoonPage/`, {
        params: {
          phoneNumber: data,
        },
      })

      .then((response) => response.data);
  }
);



