import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../../config.json";
const url = `${configData.ApiAddress}/bourse/stock`;

//#region Stock --> user Analysis
export const getUserAnalysisAction = createAsyncThunk("tahlil/getUserAnalysis", async (slug) => {
  if (slug.stocks_slug) {
    return axios.get(`${url}/tahlil/useranalysis/${slug.stocks_slug}`).then((response) => response.data);
  } else {
    return axios.get(`${url}/tahlil/useranalysis/all`).then((response) => response.data);
  }
});
//#endregion

//#region Stock --> createUserAnalysis
export const createUserAnalysisAction = createAsyncThunk("tahlil/createUserAnalysis", async (data) => {
  // console.log("data");
  // console.log(data);
  return axios.post(`${url}/tahlil/createUserAnalysis`, data).then((response) => response.data);
});
//#endregion

//#region Stock --> deleteUserAnalysis
export const deleteUserAnalysisAction = createAsyncThunk("tahlil/deleteUserAnalysis", async (data) => {
  // console.log("data");
  // console.log(data);
  return axios.delete(`${url}/tahlil/deleteUserAnalysis/${data}`).then((response) => response.data);
});
//#endregion

//#region Stock --> user Analysis
export const userAnalysisDetailsAction = createAsyncThunk("tahlil/userAnalysisDetails", async (id) => {
  return axios.get(`${url}/tahlil/userAnalysisDetails/${id}`).then((response) => response.data);
});
//#endregion

//#region Stock --> update user Analysis
export const updateUserAnalysisDetailsAction = createAsyncThunk("tahlil/updateUserAnalysisdetails", async (data) => {
  return axios.put(`${url}/tahlil/updateUserAnalysisdetails/${data.id}/`, data).then((response) => response.data);
});
//#endregion

//#region Stock --> budgetdetailanalysis
export const budgetDetailAnalysisAction = createAsyncThunk("tahlil/budgetdetailanalysis", async (data) => {
  return axios
    .get(`${url}/tahlil/budgetdetailanalysis/${data.stocks_slug}`, {
      params: {
        year: data.year,
      },
    })
    .then((response) => response.data);
});
//#endregion
