import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { commoditiesAction } from "../../redux/bourseMainRedux/bourseMainAction";

import gold from "../../assets/images/gold.jpg";
import silver from "../../assets/images/silver.jpg";
import zinc from "../../assets/images/zinc.jpg";
import lead from "../../assets/images/lead.jpg";
import brent from "../../assets/images/brent.jpg";
import copper from "../../assets/images/copper.jpg";
import aluminium from "../../assets/images/aluminium.jpg";
import nickel from "../../assets/images/nickel.jpg";
import dollar from "../../assets/images/dollar.jpg";
import platinum from "../../assets/images/platinum.jpg";
import Palladium from "../../assets/images/Palladium.webp";

import Loader from "../../components/common/Loader";
import Error from "../../components/common/Error";

const getCommodityImage = (symbol) => {
  const imageMap = {
    stooq_gold: gold,
    stooq_silver: silver,
    stooq_zinc: zinc,
    stooq_lead: lead,
    stooq_brent: brent,
    stooq_copper: copper,
    stooq_aluminium: aluminium,
    stooq_nickel: nickel,
    stooq_dollar_index: dollar,
    stooq_platinum: platinum,
    stooq_palladium: Palladium,
  };
  return imageMap[symbol];
};

const commodityOrder = [
  "stooq_dollar_index",
  "stooq_gold",
  "stooq_silver",
  "stooq_brent",
  "stooq_copper",
  "stooq_zinc",
  "stooq_lead",
  "stooq_aluminium",
  "stooq_nickel",
  "stooq_palladium",
  "stooq_platinum",
];

function Commodities() {
  const dispatch = useDispatch();
  const { commoditiesError, commoditiesLoading, commodities } = useSelector(
    (state) => state.commoditiesData
  );

  useEffect(() => {
    dispatch(commoditiesAction());
  }, [dispatch]);

  const sortedCommodities = [...commodities].sort(
    (a, b) =>
      commodityOrder.indexOf(a.symbol) - commodityOrder.indexOf(b.symbol)
  );

  const cardTheme = "bg-white text-gray-800";

  return (
    <Container
      fluid
      className={"blackbackgroundImage"}
      style={{ padding: "2rem" }}
    >





{commoditiesLoading ? (
        <Loader />
      ) : commoditiesError ? (
        <Error variant="danger"> {commoditiesError}</Error>
      ) : (




      <Row className="g-4">
             
        {sortedCommodities.map((commodity, index) => (
          <Col
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className="d-flex align-items-stretch"
          >
            <Link
              to={`/Commodities/${commodity.symbol.replace("stooq_", "")}`}
              className="text-decoration-none w-100"
            >
              <div
                className={`card ${cardTheme} shadow-lg transition-all duration-300 hover:shadow-xl w-100`}
                style={{ overflow: "hidden" }}
              >
                <div style={{ height: "200px", overflow: "hidden" }}>
                  <img
                    src={getCommodityImage(commodity.symbol)}
                    alt={commodity.symbol}
                    className="w-100 h-100 object-cover"
                  />
                </div>
                <div
                  className="card-body d-flex flex-column"
                  style={{ height: "180px" }}
                >
                  <h5
                    style={{ color: "black" }}
                    className="card-title font-bold text-lg mb-2 text-center"
                  >
                    {commodity.symbol.replace("stooq_", "").toUpperCase()}
                  </h5>
                  <p
                    style={{ color: "black" }}
                    className="card-text text-sm mb-2 text-center"
                  >
                    Last Price:{" "}
                    <span className="font-semibold">
                      {commodity.close?.toLocaleString()}
                    </span>
                  </p>
                  <p
                    style={{ color: "black" }}
                    className="card-text text-sm mt-auto text-center"
                  >
                    Last Updated:{" "}
                    <span className="font-semibold">{commodity.date}</span>
                  </p>
                </div>
              </div>
            </Link>
          </Col>
        ))}














        
      </Row>

      )}
    </Container>
  );
}

export default Commodities;
