import React from "react";
import { Alert, Card } from "react-bootstrap";

function Error({ variant, children }) {
  return (
    <Alert
    // variant={variant}
    >
      {/* {children} */}

      {children[1] == "Request failed with status code 403" ? (
        <>
          <Card style={{ backgroundColor: "transparent" }}>
            <Card.Body className="mx-auto">
              اطلاعاتی برای نمایش موجود نیست
            </Card.Body>
          </Card>
        </>
      ) : (
        <>
          <Card style={{ backgroundColor: "transparent" }}>
            <Card.Body className="mx-auto">
              اطلاعاتی برای نمایش موجود نیست
            </Card.Body>
          </Card>
        </>
      )}
    </Alert>
  );
}

export default Error;
