import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../../config.json";
const url = `${configData.ApiAddress}/bourse/stock`;

//#region Stock --> stockbox
export const stockboxAction = createAsyncThunk("main/stockbox", async (slug) => {
  return axios.get(`${url}/main/stockbox/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion


export const tseboxAction = createAsyncThunk(
  "main/tsebox",
  async (slug) => {
    return axios
      .get(`${url}/main/tsetmc/${slug.stocks_slug}`)
      .then((response) => response.data);
  }
);






//#region Stock --> order Table
export const CompetitorsTableAction = createAsyncThunk(
  "main/CompetitorsTable",
  async (slug) => {
    return axios
      .get(`${url}/main/Competitorstable/${slug}`)
      .then((response) => response.data);
  }
);
//#endregion











//#region Stock --> order Table
export const orderTableAction = createAsyncThunk("main/orderTable", async (slug) => {
  return axios.get(`${url}/main/ordertable/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

//#region Stock --> ratio Table
export const ratioTableAction = createAsyncThunk("main/ratioTable", async (slug) => {
  return axios.get(`${url}/main/ratiotable/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

//#region Stock --> StockHolder Chart
export const stockHolderAction = createAsyncThunk("main/stockHolder", async (slug) => {
  return axios.get(`${url}/main/stockholder/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

//#region Stock --> StockHolder Chart
export const dailyPriceChartAction = createAsyncThunk("main/dailyPriceChart", async (slug) => {
  return axios.get(`${url}/main/daily_price_chart/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

export const ratioTableDailyTrackingAction = createAsyncThunk("main/ratioTableDailyTracking", async (slug) => {
  return axios.get(`${url}/main/ratio_table_daily_tracking/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

export const sidebarAction = createAsyncThunk("main/sidebar", async (slug) => {
  return axios.get(`${url}/main/sidebar/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

export const sliderAction = createAsyncThunk("main/slider", async (slug) => {
  return axios.get(`${url}/main/slider/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion
