import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../config.json";
const url = `${configData.ApiAddress}/bourse`;

export const AdminUserAction = createAsyncThunk(
  "main/admin_user/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_user/`)
      .then((response) => response.data);
  }
);

export const AdminChangeUserSubscriptionAction = createAsyncThunk(
  "main/AdminChangeUserSubscription/",
  async (data, { dispatch }) => {
    console.log(data);
    try {
      const response = await axios.post(
        `${url}/main/admin_user_change_subscription/`,
        { params: data }
      );
      // After successful API response, dispatch another action
      dispatch(AdminUserAction());
      return response.data;
    } catch (error) {
      // Handle error if needed
      throw error;
    }
  }
);

export const AdminURLSAction = createAsyncThunk(
  "main/admin_urls/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_urls/`)
      .then((response) => response.data);
  }
);

export const AdminMonthlyAction = createAsyncThunk(
  "main/admin_monthly/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_monthly/`, {
        params: {
          year: data.year,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminMonthlySingleAction = createAsyncThunk(
  "main/admin_monthly/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_monthlysingle/`, {
        params: {
          selected: data.selected,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminSeasonAction = createAsyncThunk(
  "main/seasonal_admin/",
  async (data) => {
    return axios
      .get(`${url}/main/seasonal_admin/`, {
        params: {
          year: data.year,
          stock: data.stock,
        },
      })
      .then((response) => response.data);
  }
);
export const AdminSeasonDetailAction = createAsyncThunk(
  "main/seasonal_detail_admin/",
  async (data) => {
    console.log("data");
    console.log(data);
    return axios
      .get(`${url}/main/seasonal_detail_admin/`, {
        params: {
          id: data.id,
          type: data.type,
          budget: data.budget,
        },
      })
      .then((response) => response.data);
  }
);
export const AdminSeasonDetailCommodityAction = createAsyncThunk(
  "main/seasonal_detail_commodity_admin/",
  async (data) => {
    return axios
      .get(`${url}/main/seasonal_detail_commodity_admin/`, {
        params: {
          id: data.id,
          budget: data.budget,
        },
      })
      .then((response) => response.data);
  }
);
export const AdminSeasonTypeAction = createAsyncThunk(
  "main/seasonal_type_admin/",
  async (data) => {
    return axios
      .get(`${url}/main/seasonal_type_admin/`, {
        params: {
          stock: data.stock,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminSeasonFetchAction = createAsyncThunk(
  "main/seasonal_fetch_admin/",
  async (data) => {
    if (url.contains("afranova.com")) return;
    else
      return axios.get(`${url}/main/seasonal_fetch_admin/`, {
        params: {
          id: data.id,
        },
      });
    // .then((response) => response.data);
  }
);

export const AdminSeasonUpdateAction = createAsyncThunk(
  "main/admin_season_update/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_season_update/`, {
        params: {
          year: data.year,
          stock: data.stock,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminSeasonDeleteYearAction = createAsyncThunk(
  "main/admin_season_delete_year/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_season_delete_year/`, {
        params: {
          stock: data.stock,
          year: data.year,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminSeasonDeleteSubjectAction = createAsyncThunk(
  "main/admin_season_delete_subject/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_season_delete_subject/`, {
        params: {
          subject: data.subject,
          year: data.year,
          stock: data.stock,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminSeasonAddAction = createAsyncThunk(
  "main/admin_season_add/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_season_add/`, {
        params: {
          name: data.name,
          year: data.year,
          report_type: data.report_type,

          daraman: data.daraman,
          baha: data.baha,
          sood_nakhales: data.sood_nakhales,

          edari: data.edari,
          sood_amaliati: data.sood_amaliati,
          sood_khales: data.sood_khales,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminAssemblyAddAction = createAsyncThunk(
  "main/admin_Assembly_add/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_Assembly_add/`, {
        params: {
          name: data.name,
          year: data.year,

          EPS: data.EPS,
          DPS: data.DPS,
          sarmaye: data.sarmaye,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminSeasonSingleAction = createAsyncThunk(
  "main/AdminSeasonSingleAction",
  async (data) => {
    return axios
      .get(`${url}/main/admin_season_single/`, {
        params: {
          name_update: data.selected,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminBalanceAction = createAsyncThunk(
  "main/admin_balance/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_balance/`)
      .then((response) => response.data);
  }
);

export const AdminBalanceSingleAction = createAsyncThunk(
  "main/admin_balance_single/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_balance_single/`, {
        params: {
          name_update: data.selected,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminMonthlyDeleteAction = createAsyncThunk(
  "main/admin_monthly/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_monthlyDelete/`, {
        params: {
          id: data.stock.id,
          m: data.m,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminAssemblyAction = createAsyncThunk(
  "main/admin_assembly/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_assembly/`)
      .then((response) => response.data);
  }
);

export const AdminBudgetAction = createAsyncThunk(
  "main/admin_Budget/",
  async (data) => {
    return axios
      .get(`${url}/main/admin_Budget/`)
      .then((response) => response.data);
  }
);

export const AdminRealStateAction = createAsyncThunk(
  "main/admin_realstate/",
  async (data) => {
    return axios

      .get(`${url}/main/admin_realstate/`, {
        // params: {
        //   year: data.year,
        // },
      })
      .then((response) => response.data);
  }
);

export const AdminAddStockAction = createAsyncThunk(
  "main/AdminAddStock",
  async (data) => {
    return axios
      .get(`${url}/main/AdminAddStock/`, {
        params: {
          name: data.name,
          english_name: data.english_name,
          company_name: data.company_name,

          type: data.type,
          group: data.group,
          tablo: data.tablo,

          url_stockholder: data.url_stockholder,
          url_tse: data.url_tse,
          url_codal: data.url_codal,

          url_ime: data.url_ime,
        },
      })
      .then((response) => response.data);
  }
);

export const AdmindeleteStockAction = createAsyncThunk(
  "main/AdmindeleteStock",
  async (data) => {
    return axios
      .get(`${url}/main/AdmindeleteStock/`, {
        params: {
          name_delete: data.name_delete,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminupdateStockAction = createAsyncThunk(
  "main/AdminupdateStock",
  async (data) => {
    return axios
      .get(`${url}/main/AdminupdateStock/`, {
        params: {
          name_update: data.selected,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminmainAction = createAsyncThunk(
  "main/AdminmainAction",
  async () => {
    return axios
      .get(`${url}/main/admin_main/`)
      .then((response) => response.data);
  }
);

export const AdminTecnicalChartAction = createAsyncThunk(
  "main/AdminTecnicalChart",
  async (data) => {
    return axios
      .get(`${url}/main/AdminTecnicalChart/`, {
        params: {
          selected: data.selected,
        },
      })
      .then((response) => response.data);
  }
);

export const AdminGoldDollarAction = createAsyncThunk(
  "main/AdmingolddollarAction",
  async (data) => {
    return axios
      .get(`${url}/main/admin_gold_dollar/`)
      .then((response) => response.data);
  }
);
