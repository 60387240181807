import { createSlice } from "@reduxjs/toolkit";

import {
  publicationsAction,
  publicationAction,
  sendChatbotMessageAction,
} from "./mainPageAction";

//#region publications
export const publicationsSlice = createSlice({
  name: "publicationsData",
  initialState: {
    publicationsLoading: false,
    publications: [],
    publicationsError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch publicationsAction
    builder.addCase(publicationsAction.pending, (state, action) => {
      state.publicationsLoading = true;
    });
    builder.addCase(publicationsAction.fulfilled, (state, action) => {
      state.publicationsLoading = false;
      state.publications = action.payload;
      state.publicationsError = null;
    });
    builder.addCase(publicationsAction.rejected, (state, action) => {
      state.publicationsLoading = false;
      state.publicationsError = action.error.message;
    });
  },
});
//#endregion

//#region publication
export const publicationSlice = createSlice({
  name: "publicationData",
  initialState: {
    publicationLoading: false,
    publication: [],
    publicationError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch publicationAction
    builder.addCase(publicationAction.pending, (state, action) => {
      state.publicationLoading = true;
    });
    builder.addCase(publicationAction.fulfilled, (state, action) => {
      state.publicationLoading = false;
      state.publication = action.payload;
      state.publicationError = null;
    });
    builder.addCase(publicationAction.rejected, (state, action) => {
      state.publicationLoading = false;
      state.publicationError = action.error.message;
    });
  },
});
//#endregion

export const chatbotSlice = createSlice({
  name: "chatbotData",
  initialState: {
    chatbotLoading: false,
    chatbot: [],
    chatbotError: null,
  },
  reducers: {
    clearHandleError: (state) => {
      state.chatbotError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendChatbotMessageAction.pending, (state) => {
      state.chatbotLoading = true;
    });
    builder.addCase(sendChatbotMessageAction.fulfilled, (state, action) => {
      state.chatbotLoading = false;
      state.chatbot = action.payload;
      // state.chatbot.push({
      //   message: action.payload,
      //   sender: "DaraGPT",
      //   direction: "outgoing",
      // });
      state.chatbotError = null;
    });
    builder.addCase(sendChatbotMessageAction.rejected, (state, action) => {
      state.chatbotLoading = false;
      state.chatbotError = action.error.message;
    });
  },
});
