import { createSlice } from "@reduxjs/toolkit";

import { PriceActionAction, OHLCAction, IndicatorAction, ChartPatternAction, ConvergenceDivergenceAction } from "./bourseStockAiAction";

//#region price_action_Action Component
export const PriceActionSlice = createSlice({
  name: "PriceActionData",
  initialState: {
    PriceActionLoading: false,
    PriceAction: [],
    PriceActionError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch PriceActionAction
    builder.addCase(PriceActionAction.pending, (state, action) => {
      state.PriceActionLoading = true;
    });
    builder.addCase(PriceActionAction.fulfilled, (state, action) => {
      state.PriceActionLoading = false;
      state.PriceAction = action.payload;
      state.PriceActionError = null;
    });
    builder.addCase(PriceActionAction.rejected, (state, action) => {
      state.PriceActionLoading = false;
      state.PriceActionError = action.error.message;
    });
  },
});
//#endregion

//#region price_action_Action Component
export const ConvergenceDivergenceSlice = createSlice({
  name: "ConvergenceDivergenceData",
  initialState: {
    ConvergenceDivergenceLoading: false,
    ConvergenceDivergence: [],
    ConvergenceDivergenceError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch ConvergenceDivergenceAction
    builder.addCase(ConvergenceDivergenceAction.pending, (state, action) => {
      state.ConvergenceDivergenceLoading = true;
    });
    builder.addCase(ConvergenceDivergenceAction.fulfilled, (state, action) => {
      state.ConvergenceDivergenceLoading = false;
      state.ConvergenceDivergence = action.payload;
      state.ConvergenceDivergenceError = null;
    });
    builder.addCase(ConvergenceDivergenceAction.rejected, (state, action) => {
      state.ConvergenceDivergenceLoading = false;
      state.ConvergenceDivergenceError = action.error.message;
    });
  },
});
//#endregion

//#region price_action_Action Component
export const OHLCSlice = createSlice({
  name: "OHLCData",
  initialState: {
    OHLCLoading: false,
    OHLC: [],
    OHLCError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch OHLCAction
    builder.addCase(OHLCAction.pending, (state, action) => {
      state.OHLCLoading = true;
    });
    builder.addCase(OHLCAction.fulfilled, (state, action) => {
      state.OHLCLoading = false;
      state.OHLC = action.payload;
      state.OHLCError = null;
    });
    builder.addCase(OHLCAction.rejected, (state, action) => {
      state.OHLCLoading = false;
      state.OHLCError = action.error.message;
    });
  },
});
//#endregion

//#region price_action_Action Component
export const ChartPatternSlice = createSlice({
  name: "ChartPatternData",
  initialState: {
    ChartPatternLoading: false,
    ChartPattern: [],
    ChartPatternError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch ChartPatternAction
    builder.addCase(ChartPatternAction.pending, (state, action) => {
      state.ChartPatternLoading = true;
    });
    builder.addCase(ChartPatternAction.fulfilled, (state, action) => {
      state.ChartPatternLoading = false;
      state.ChartPattern = action.payload;
      state.ChartPatternError = null;
    });
    builder.addCase(ChartPatternAction.rejected, (state, action) => {
      state.ChartPatternLoading = false;
      state.ChartPatternError = action.error.message;
    });
  },
});
//#endregion

//#region price_action_Action Component
export const IndicatorSlice = createSlice({
  name: "IndicatorData",
  initialState: {
    IndicatorLoading: false,
    Indicator: [],
    IndicatorError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch IndicatorAction
    builder.addCase(IndicatorAction.pending, (state, action) => {
      state.IndicatorLoading = true;
    });
    builder.addCase(IndicatorAction.fulfilled, (state, action) => {
      state.IndicatorLoading = false;
      state.Indicator = action.payload;
      state.IndicatorError = null;
    });
    builder.addCase(IndicatorAction.rejected, (state, action) => {
      state.IndicatorLoading = false;
      state.IndicatorError = action.error.message;
    });
  },
});
//#endregion
