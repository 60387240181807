import { createSlice } from "@reduxjs/toolkit";

import {
  FundBoxAction,
  FundTseBoxAction,
  FundCompetitorsTableAction,
  FundChartAction,
  fundratioTableDailyTrackingAction,
  fundorderTableAction,
  fundsliderAction,
  fundHolderAction,
  fundratioTableAction
} from "./bourseFundMainPageAction";

//#region Stocks Groups Page
export const FundBoxSlice = createSlice({
  name: "FundBoxData",
  initialState: {
    FundBoxLoading: false,
    FundBox: [],
    FundBoxError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch FundBoxAction
    builder.addCase(FundBoxAction.pending, (state, action) => {
      state.FundBoxLoading = true;
    });
    builder.addCase(FundBoxAction.fulfilled, (state, action) => {
      state.FundBoxLoading = false;
      state.FundBox = action.payload;
      state.FundBoxError = null;
    });
    builder.addCase(FundBoxAction.rejected, (state, action) => {
      state.FundBoxLoading = false;
      state.FundBoxError = action.error.message;
    });
  },
});
//#endregion

//#region Stocks Groups Page
export const FundTseBoxSlice = createSlice({
  name: "FundTseBoxData",
  initialState: {
    FundTseBoxLoading: false,
    FundTseBox: [],
    FundTseBoxError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch FundTseBoxAction
    builder.addCase(FundTseBoxAction.pending, (state, action) => {
      state.FundTseBoxLoading = true;
    });
    builder.addCase(FundTseBoxAction.fulfilled, (state, action) => {
      state.FundTseBoxLoading = false;
      state.FundTseBox = action.payload;
      state.FundTseBoxError = null;
    });
    builder.addCase(FundTseBoxAction.rejected, (state, action) => {
      state.FundTseBoxLoading = false;
      state.FundTseBoxError = action.error.message;
    });
  },
});
//#endregion

//#region Stocks Groups Page
export const FundChartSlice = createSlice({
  name: "FundChartData",
  initialState: {
    FundChartLoading: false,
    FundChart: [],
    FundChartError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch FundChartAction
    builder.addCase(FundChartAction.pending, (state, action) => {
      state.FundChartLoading = true;
    });
    builder.addCase(FundChartAction.fulfilled, (state, action) => {
      state.FundChartLoading = false;
      state.FundChart = action.payload;
      state.FundChartError = null;
    });
    builder.addCase(FundChartAction.rejected, (state, action) => {
      state.FundChartLoading = false;
      state.FundChartError = action.error.message;
    });
  },
});
//#endregion

//#region Stocks Groups Page
export const fundratioTableDailyTrackingSlice = createSlice({
  name: "fundratioTableDailyTrackingData",
  initialState: {
    fundratioTableDailyTrackingLoading: false,
    fundratioTableDailyTracking: [],
    fundratioTableDailyTrackingError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch fundratioTableDailyTrackingAction
    builder.addCase(
      fundratioTableDailyTrackingAction.pending,
      (state, action) => {
        state.fundratioTableDailyTrackingLoading = true;
      }
    );
    builder.addCase(
      fundratioTableDailyTrackingAction.fulfilled,
      (state, action) => {
        state.fundratioTableDailyTrackingLoading = false;
        state.fundratioTableDailyTracking = action.payload;
        state.fundratioTableDailyTrackingError = null;
      }
    );
    builder.addCase(
      fundratioTableDailyTrackingAction.rejected,
      (state, action) => {
        state.fundratioTableDailyTrackingLoading = false;
        state.fundratioTableDailyTrackingError = action.error.message;
      }
    );
  },
});
//#endregion

//#region Stocks Groups Page
export const fundorderTableSlice = createSlice({
  name: "fundorderTableData",
  initialState: {
    fundorderTableLoading: false,
    fundorderTable: [],
    fundorderTableError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch fundorderTableAction
    builder.addCase(fundorderTableAction.pending, (state, action) => {
      state.fundorderTableLoading = true;
    });
    builder.addCase(fundorderTableAction.fulfilled, (state, action) => {
      state.fundorderTableLoading = false;
      state.fundorderTable = action.payload;
      state.fundorderTableError = null;
    });
    builder.addCase(fundorderTableAction.rejected, (state, action) => {
      state.fundorderTableLoading = false;
      state.fundorderTableError = action.error.message;
    });
  },
});
//#endregion

//#region Stocks Groups Page
export const fundsliderSlice = createSlice({
  name: "fundsliderData",
  initialState: {
    fundsliderLoading: false,
    fundslider: [],
    fundsliderError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch fundsliderAction
    builder.addCase(fundsliderAction.pending, (state, action) => {
      state.fundsliderLoading = true;
    });
    builder.addCase(fundsliderAction.fulfilled, (state, action) => {
      state.fundsliderLoading = false;
      state.fundslider = action.payload;
      state.fundsliderError = null;
    });
    builder.addCase(fundsliderAction.rejected, (state, action) => {
      state.fundsliderLoading = false;
      state.fundsliderError = action.error.message;
    });
  },
});
//#endregion

//#region Stocks Groups Page
export const FundCompetitorsTableSlice = createSlice({
  name: "FundCompetitorsTableData",
  initialState: {
    FundCompetitorsTableLoading: false,
    FundCompetitorsTable: [],
    FundCompetitorsTableError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch FundCompetitorsTableAction
    builder.addCase(FundCompetitorsTableAction.pending, (state, action) => {
      state.FundCompetitorsTableLoading = true;
    });
    builder.addCase(FundCompetitorsTableAction.fulfilled, (state, action) => {
      state.FundCompetitorsTableLoading = false;
      state.FundCompetitorsTable = action.payload;
      state.FundCompetitorsTableError = null;
    });
    builder.addCase(FundCompetitorsTableAction.rejected, (state, action) => {
      state.FundCompetitorsTableLoading = false;
      state.FundCompetitorsTableError = action.error.message;
    });
  },
});
//#endregion





//#region Stocks Groups Page
export const fundHolderSlice = createSlice({
  name: "fundHolderData",
  initialState: {
    fundHolderLoading: false,
    fundHolder: [],
    fundHolderError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch fundHolderAction
    builder.addCase(fundHolderAction.pending, (state, action) => {
      state.fundHolderLoading = true;
    });
    builder.addCase(fundHolderAction.fulfilled, (state, action) => {
      state.fundHolderLoading = false;
      state.fundHolder = action.payload;
      state.fundHolderError = null;
    });
    builder.addCase(fundHolderAction.rejected, (state, action) => {
      state.fundHolderLoading = false;
      state.fundHolderError = action.error.message;
    });
  },
});
//#endregion









//#region Stocks Groups Page
export const fundratioTableSlice = createSlice({
  name: "fundratioTableData",
  initialState: {
    fundratioTableLoading: false,
    fundratioTable: [],
    fundratioTableError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch fundratioTableAction
    builder.addCase(fundratioTableAction.pending, (state, action) => {
      state.fundratioTableLoading = true;
    });
    builder.addCase(fundratioTableAction.fulfilled, (state, action) => {
      state.fundratioTableLoading = false;
      state.fundratioTable = action.payload;
      state.fundratioTableError = null;
    });
    builder.addCase(fundratioTableAction.rejected, (state, action) => {
      state.fundratioTableLoading = false;
      state.fundratioTableError = action.error.message;
    });
  },
});
//#endregion





