// src/analytics.js
import ReactGA from "react-ga4";

// Initialize GA4 with your Measurement ID
export const initializeAnalytics = () => {
  ReactGA.initialize("G-8RFHL7WSJS"); // Replace with your Measurement ID
};

// Log page views
export const logPageView = (path = window.location.pathname) => {
  ReactGA.send({ hitType: "pageview", page: path });
};
