import { createSlice } from "@reduxjs/toolkit";
import {
  MarketTrackingAction,
  dailyIndexAction,
  smartMoneyAction,
  stocksGroupsAction,
  GoldCurrencyCommodityParagraphAction,
  FundsGroupsAction,
  stocksListAction,
  commoditiesAction,
  FilterAction,
  marketMapAction,
  MarketMAction,
  allStocksAction,
  allFundAction,
  StockHolderFilterAction,
  AddToCartAction,
  CartAction,
  UserThemeAction,
  deletefromcartAction,
  AssemblyFilterAction,
  MonthlyFilterAction,
  MarginFilterAction,
  PriceActionFilterAction,
  ChartPatternsFilterAction,
  PEPSFilterAction,
  MonthReportAction,
  BubbleMapAction,
  MonthReport1Action,
  AddStockAction,
  MarketMFAction,
  indextrackingAction,
  line_trackingAction,
  fundsListAction,
  DaramadSabetTrackingAction,
  boursemainimeAction,
  goldrestAction,
  singlecommoAction,
  ComingSoonPageAction,
  ImeTodayAction,
  ImeTalarAction,
  ImeCommodityAction,
  OtherImeCommodityAction,
  ComparisonAction,
  MonthlyComparisonnamesAction,
  MonthlyComparisonAction,
  GoldGroupAction,
  GoldFundAction,
  HaghAction,
  ArzAction,
  goldcoinbourseAction,
  goldcoinazadAction,
  GoldCurrencyAction,

} from "./bourseMainAction";

//#region Bourse Main Page

export const MarketTrackingSlice = createSlice({
  name: "MarketTrackingData",
  initialState: {
    MarketTrackingLoading: false,
    MarketTracking: [],
    MarketTrackingError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch MarketTrackingAction
    builder.addCase(MarketTrackingAction.pending, (state, action) => {
      state.MarketTrackingLoading = true;
    });
    builder.addCase(MarketTrackingAction.fulfilled, (state, action) => {
      state.MarketTrackingLoading = false;
      state.MarketTracking = action.payload;
      state.MarketTrackingError = null;
    });
    builder.addCase(MarketTrackingAction.rejected, (state, action) => {
      state.MarketTrackingLoading = false;
      state.MarketTrackingError = action.error.message;
    });
  },
});

export const dailyIndexSlice = createSlice({
  name: "dailyIndexData",
  initialState: {
    dailyIndexLoading: false,
    dailyIndex: [],
    dailyIndexError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch dailyIndexAction
    builder.addCase(dailyIndexAction.pending, (state, action) => {
      state.dailyIndexLoading = true;
    });
    builder.addCase(dailyIndexAction.fulfilled, (state, action) => {
      state.dailyIndexLoading = false;
      state.dailyIndex = action.payload;
      state.dailyIndexError = null;
    });
    builder.addCase(dailyIndexAction.rejected, (state, action) => {
      state.dailyIndexLoading = false;
      state.dailyIndexError = action.error.message;
    });
  },
});

export const smartMoneySlice = createSlice({
  name: "smartMoneyData",
  initialState: {
    smartMoneyLoading: false,
    smartMoney: [],
    smartMoneyError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch smartMoneyAction
    builder.addCase(smartMoneyAction.pending, (state, action) => {
      state.smartMoneyLoading = true;
    });
    builder.addCase(smartMoneyAction.fulfilled, (state, action) => {
      state.smartMoneyLoading = false;
      state.smartMoney = action.payload;
      state.smartMoneyError = null;
    });
    builder.addCase(smartMoneyAction.rejected, (state, action) => {
      state.smartMoneyLoading = false;
      state.smartMoneyError = action.error.message;
    });
  },
});
//#endregion














//#region Stocks Groups Page
export const fundsListSlice = createSlice({
  name: "fundsListData",
  initialState: {
    fundsListLoading: false,
    fundsList: [],
    fundsListError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch fundsListAction
    builder.addCase(fundsListAction.pending, (state, action) => {
      state.fundsListLoading = true;
    });
    builder.addCase(fundsListAction.fulfilled, (state, action) => {
      state.fundsListLoading = false;
      state.fundsList = action.payload;
      state.fundsListError = null;
    });
    builder.addCase(fundsListAction.rejected, (state, action) => {
      state.fundsListLoading = false;
      state.fundsListError = action.error.message;
    });
  },
});
//#endregion







//#region Stocks Groups Page
export const FundsGroupsSlice = createSlice({
  name: "FundsGroupsData",
  initialState: {
    FundsGroupsLoading: false,
    FundsGroups: [],
    FundsGroupsError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch FundsGroupsAction
    builder.addCase(FundsGroupsAction.pending, (state, action) => {
      state.FundsGroupsLoading = true;
    });
    builder.addCase(FundsGroupsAction.fulfilled, (state, action) => {
      state.FundsGroupsLoading = false;
      state.FundsGroups = action.payload;
      state.FundsGroupsError = null;
    });
    builder.addCase(FundsGroupsAction.rejected, (state, action) => {
      state.FundsGroupsLoading = false;
      state.FundsGroupsError = action.error.message;
    });
  },
});
//#endregion






//#region Stocks Groups Page
export const BubbleMapSlice = createSlice({
  name: "BubbleMapData",
  initialState: {
    BubbleMapLoading: false,
    BubbleMap: [],
    BubbleMapError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch BubbleMapAction
    builder.addCase(BubbleMapAction.pending, (state, action) => {
      state.BubbleMapLoading = true;
    });
    builder.addCase(BubbleMapAction.fulfilled, (state, action) => {
      state.BubbleMapLoading = false;
      state.BubbleMap = action.payload;
      state.BubbleMapError = null;
    });
    builder.addCase(BubbleMapAction.rejected, (state, action) => {
      state.BubbleMapLoading = false;
      state.BubbleMapError = action.error.message;
    });
  },
});
//#endregion




//#region Stocks Groups Page
export const indextrackingSlice = createSlice({
  name: "indextrackingData",
  initialState: {
    indextrackingLoading: false,
    indextracking: [],
    indextrackingError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch indextrackingAction
    builder.addCase(indextrackingAction.pending, (state, action) => {
      state.indextrackingLoading = true;
    });
    builder.addCase(indextrackingAction.fulfilled, (state, action) => {
      state.indextrackingLoading = false;
      state.indextracking = action.payload;
      state.indextrackingError = null;
    });
    builder.addCase(indextrackingAction.rejected, (state, action) => {
      state.indextrackingLoading = false;
      state.indextrackingError = action.error.message;
    });
  },
});
//#endregion












//#region Stocks Groups Page
export const GoldGroupSlice = createSlice({
  name: "GoldGroupData",
  initialState: {
    GoldGroupLoading: false,
    GoldGroup: [],
    GoldGroupError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch GoldGroupAction
    builder.addCase(GoldGroupAction.pending, (state, action) => {
      state.GoldGroupLoading = true;
    });
    builder.addCase(GoldGroupAction.fulfilled, (state, action) => {
      state.GoldGroupLoading = false;
      state.GoldGroup = action.payload;
      state.GoldGroupError = null;
    });
    builder.addCase(GoldGroupAction.rejected, (state, action) => {
      state.GoldGroupLoading = false;
      state.GoldGroupError = action.error.message;
    });
  },
});
//#endregion




//#region Stocks Groups Page
export const GoldFundSlice = createSlice({
  name: "GoldFundData",
  initialState: {
    GoldFundLoading: false,
    GoldFund: [],
    GoldFundError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch GoldFundAction
    builder.addCase(GoldFundAction.pending, (state, action) => {
      state.GoldFundLoading = true;
    });
    builder.addCase(GoldFundAction.fulfilled, (state, action) => {
      state.GoldFundLoading = false;
      state.GoldFund = action.payload;
      state.GoldFundError = null;
    });
    builder.addCase(GoldFundAction.rejected, (state, action) => {
      state.GoldFundLoading = false;
      state.GoldFundError = action.error.message;
    });
  },
});
//#endregion








//#region Stocks Groups Page
export const HaghSlice = createSlice({
  name: "HaghData",
  initialState: {
    HaghLoading: false,
    Hagh: [],
    HaghError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch HaghAction
    builder.addCase(HaghAction.pending, (state, action) => {
      state.HaghLoading = true;
    });
    builder.addCase(HaghAction.fulfilled, (state, action) => {
      state.HaghLoading = false;
      state.Hagh = action.payload;
      state.HaghError = null;
    });
    builder.addCase(HaghAction.rejected, (state, action) => {
      state.HaghLoading = false;
      state.HaghError = action.error.message;
    });
  },
});
//#endregion





//#region Stocks Groups Page
export const goldrestSlice = createSlice({
  name: "goldrestData",
  initialState: {
    goldrestLoading: false,
    goldrest: [],
    goldrestError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch goldrestAction
    builder.addCase(goldrestAction.pending, (state, action) => {
      state.goldrestLoading = true;
    });
    builder.addCase(goldrestAction.fulfilled, (state, action) => {
      state.goldrestLoading = false;
      state.goldrest = action.payload;
      state.goldrestError = null;
    });
    builder.addCase(goldrestAction.rejected, (state, action) => {
      state.goldrestLoading = false;
      state.goldrestError = action.error.message;
    });
  },
});
//#endregion











//#region Stocks Groups Page
export const MarketMSlice = createSlice({
  name: "MarketMData",
  initialState: {
    MarketMLoading: false,
    MarketM: [],
    MarketMError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch MarketMAction
    builder.addCase(MarketMAction.pending, (state, action) => {
      state.MarketMLoading = true;
    });
    builder.addCase(MarketMAction.fulfilled, (state, action) => {
      state.MarketMLoading = false;
      state.MarketM = action.payload;
      state.MarketMError = null;
    });
    builder.addCase(MarketMAction.rejected, (state, action) => {
      state.MarketMLoading = false;
      state.MarketMError = action.error.message;
    });
  },
});
//#endregion







//#region Stocks Groups Page
export const GoldCurrencyCommodityParagraphSlice = createSlice({
  name: "GoldCurrencyCommodityParagraphData",
  initialState: {
    GoldCurrencyCommodityParagraphLoading: false,
    GoldCurrencyCommodityParagraph: [],
    GoldCurrencyCommodityParagraphError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch GoldCurrencyCommodityParagraphAction
    builder.addCase(GoldCurrencyCommodityParagraphAction.pending, (state, action) => {
      state.GoldCurrencyCommodityParagraphLoading = true;
    });
    builder.addCase(GoldCurrencyCommodityParagraphAction.fulfilled, (state, action) => {
      state.GoldCurrencyCommodityParagraphLoading = false;
      state.GoldCurrencyCommodityParagraph = action.payload;
      state.GoldCurrencyCommodityParagraphError = null;
    });
    builder.addCase(GoldCurrencyCommodityParagraphAction.rejected, (state, action) => {
      state.GoldCurrencyCommodityParagraphLoading = false;
      state.GoldCurrencyCommodityParagraphError = action.error.message;
    });
  },
});
//#endregion
















//#region Stocks Groups Page
export const MarketMFSlice = createSlice({
  name: "MarketMFData",
  initialState: {
    MarketMFLoading: false,
    MarketMF: [],
    MarketMFError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch MarketMFAction
    builder.addCase(MarketMFAction.pending, (state, action) => {
      state.MarketMFLoading = true;
    });
    builder.addCase(MarketMFAction.fulfilled, (state, action) => {
      state.MarketMFLoading = false;
      state.MarketMF = action.payload;
      state.MarketMFError = null;
    });
    builder.addCase(MarketMFAction.rejected, (state, action) => {
      state.MarketMFLoading = false;
      state.MarketMFError = action.error.message;
    });
  },
});
//#endregion




//#region Stocks Groups Page
export const ArzSlice = createSlice({
  name: "ArzData",
  initialState: {
    ArzLoading: false,
    Arz: [],
    ArzError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch ArzAction
    builder.addCase(ArzAction.pending, (state, action) => {
      state.ArzLoading = true;
    });
    builder.addCase(ArzAction.fulfilled, (state, action) => {
      state.ArzLoading = false;
      state.Arz = action.payload;
      state.ArzError = null;
    });
    builder.addCase(ArzAction.rejected, (state, action) => {
      state.ArzLoading = false;
      state.ArzError = action.error.message;
    });
  },
});
//#endregion






//#region Stocks Groups Page
export const goldcoinbourseSlice = createSlice({
  name: "goldcoinbourseData",
  initialState: {
    goldcoinbourseLoading: false,
    goldcoinbourse: [],
    goldcoinbourseError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch goldcoinbourseAction
    builder.addCase(goldcoinbourseAction.pending, (state, action) => {
      state.goldcoinbourseLoading = true;
    });
    builder.addCase(goldcoinbourseAction.fulfilled, (state, action) => {
      state.goldcoinbourseLoading = false;
      state.goldcoinbourse = action.payload;
      state.goldcoinbourseError = null;
    });
    builder.addCase(goldcoinbourseAction.rejected, (state, action) => {
      state.goldcoinbourseLoading = false;
      state.goldcoinbourseError = action.error.message;
    });
  },
});
//#endregion








//#region Stocks Groups Page
export const goldcoinazadSlice = createSlice({
  name: "goldcoinazadData",
  initialState: {
    goldcoinazadLoading: false,
    goldcoinazad: [],
    goldcoinazadError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch goldcoinazadAction
    builder.addCase(goldcoinazadAction.pending, (state, action) => {
      state.goldcoinazadLoading = true;
    });
    builder.addCase(goldcoinazadAction.fulfilled, (state, action) => {
      state.goldcoinazadLoading = false;
      state.goldcoinazad = action.payload;
      state.goldcoinazadError = null;
    });
    builder.addCase(goldcoinazadAction.rejected, (state, action) => {
      state.goldcoinazadLoading = false;
      state.goldcoinazadError = action.error.message;
    });
  },
});
//#endregion






//#region Stocks Groups Page
export const GoldCurrencySlice = createSlice({
  name: "GoldCurrencyData",
  initialState: {
    GoldCurrencyLoading: false,
    GoldCurrency: [],
    GoldCurrencyError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch GoldCurrencyAction
    builder.addCase(GoldCurrencyAction.pending, (state, action) => {
      state.GoldCurrencyLoading = true;
    });
    builder.addCase(GoldCurrencyAction.fulfilled, (state, action) => {
      state.GoldCurrencyLoading = false;
      state.GoldCurrency = action.payload;
      state.GoldCurrencyError = null;
    });
    builder.addCase(GoldCurrencyAction.rejected, (state, action) => {
      state.GoldCurrencyLoading = false;
      state.GoldCurrencyError = action.error.message;
    });
  },
});
//#endregion










//#region Stocks Groups Page
export const ImeTodaySlice = createSlice({
  name: "ImeTodayData",
  initialState: {
    ImeTodayLoading: false,
    ImeToday: [],
    ImeTodayError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch ImeTodayAction
    builder.addCase(ImeTodayAction.pending, (state, action) => {
      state.ImeTodayLoading = true;
    });
    builder.addCase(ImeTodayAction.fulfilled, (state, action) => {
      state.ImeTodayLoading = false;
      state.ImeToday = action.payload;
      state.ImeTodayError = null;
    });
    builder.addCase(ImeTodayAction.rejected, (state, action) => {
      state.ImeTodayLoading = false;
      state.ImeTodayError = action.error.message;
    });
  },
});
//#endregion











//#region Stocks Groups Page
export const ImeTalarSlice = createSlice({
  name: "ImeTalarData",
  initialState: {
    ImeTalarLoading: false,
    ImeTalar: [],
    ImeTalarError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch ImeTalarAction
    builder.addCase(ImeTalarAction.pending, (state, action) => {
      state.ImeTalarLoading = true;
    });
    builder.addCase(ImeTalarAction.fulfilled, (state, action) => {
      state.ImeTalarLoading = false;
      state.ImeTalar = action.payload;
      state.ImeTalarError = null;
    });
    builder.addCase(ImeTalarAction.rejected, (state, action) => {
      state.ImeTalarLoading = false;
      state.ImeTalarError = action.error.message;
    });
  },
});
//#endregion





//#region Stocks Groups Page
export const ImeCommoditySlice = createSlice({
  name: "ImeCommodityData",
  initialState: {
    ImeCommodityLoading: false,
    ImeCommodity: [],
    ImeCommodityError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch ImeCommodityAction
    builder.addCase(ImeCommodityAction.pending, (state, action) => {
      state.ImeCommodityLoading = true;
    });
    builder.addCase(ImeCommodityAction.fulfilled, (state, action) => {
      state.ImeCommodityLoading = false;
      state.ImeCommodity = action.payload;
      state.ImeCommodityError = null;
    });
    builder.addCase(ImeCommodityAction.rejected, (state, action) => {
      state.ImeCommodityLoading = false;
      state.ImeCommodityError = action.error.message;
    });
  },
});
//#endregion








//#region Stocks Groups Page
export const OtherImeCommoditySlice = createSlice({
  name: "OtherImeCommodityData",
  initialState: {
    OtherImeCommodityLoading: false,
    OtherImeCommodity: [],
    OtherImeCommodityError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch OtherImeCommodityAction
    builder.addCase(OtherImeCommodityAction.pending, (state, action) => {
      state.OtherImeCommodityLoading = true;
    });
    builder.addCase(OtherImeCommodityAction.fulfilled, (state, action) => {
      state.OtherImeCommodityLoading = false;
      state.OtherImeCommodity = action.payload;
      state.OtherImeCommodityError = null;
    });
    builder.addCase(OtherImeCommodityAction.rejected, (state, action) => {
      state.OtherImeCommodityLoading = false;
      state.OtherImeCommodityError = action.error.message;
    });
  },
});
//#endregion





















//#region Stocks Groups Page
export const ComparisonSlice = createSlice({
  name: "ComparisonData",
  initialState: {
    ComparisonLoading: false,
    Comparison: [],
    ComparisonError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch ComparisonAction
    builder.addCase(ComparisonAction.pending, (state, action) => {
      state.ComparisonLoading = true;
    });
    builder.addCase(ComparisonAction.fulfilled, (state, action) => {
      state.ComparisonLoading = false;
      state.Comparison = action.payload;
      state.ComparisonError = null;
    });
    builder.addCase(ComparisonAction.rejected, (state, action) => {
      state.ComparisonLoading = false;
      state.ComparisonError = action.error.message;
    });
  },
});
//#endregion







//#region Stocks Groups Page
export const MonthlyComparisonnamesSlice = createSlice({
  name: "MonthlyComparisonnamesData",
  initialState: {
    MonthlyComparisonnamesLoading: false,
    MonthlyComparisonnames: [],
    MonthlyComparisonnamesError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch MonthlyComparisonnamesAction
    builder.addCase(MonthlyComparisonnamesAction.pending, (state, action) => {
      state.MonthlyComparisonnamesLoading = true;
    });
    builder.addCase(MonthlyComparisonnamesAction.fulfilled, (state, action) => {
      state.MonthlyComparisonnamesLoading = false;
      state.MonthlyComparisonnames = action.payload;
      state.MonthlyComparisonnamesError = null;
    });
    builder.addCase(MonthlyComparisonnamesAction.rejected, (state, action) => {
      state.MonthlyComparisonnamesLoading = false;
      state.MonthlyComparisonnamesError = action.error.message;
    });
  },
});
//#endregion




//#region Stocks Groups Page
export const MonthlyComparisonSlice = createSlice({
  name: "MonthlyComparisonData",
  initialState: {
    MonthlyComparisonLoading: false,
    MonthlyComparison: [],
    MonthlyComparisonError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch MonthlyComparisonAction
    builder.addCase(MonthlyComparisonAction.pending, (state, action) => {
      state.MonthlyComparisonLoading = true;
    });
    builder.addCase(MonthlyComparisonAction.fulfilled, (state, action) => {
      state.MonthlyComparisonLoading = false;
      state.MonthlyComparison = action.payload;
      state.MonthlyComparisonError = null;
    });
    builder.addCase(MonthlyComparisonAction.rejected, (state, action) => {
      state.MonthlyComparisonLoading = false;
      state.MonthlyComparisonError = action.error.message;
    });
  },
});
//#endregion




//#region Stocks Groups Page
export const stocksGroupsSlice = createSlice({
  name: "stocksGroupsData",
  initialState: {
    stocksGroupsLoading: false,
    stocksGroups: [],
    stocksGroupsError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch stocksGroupsAction
    builder.addCase(stocksGroupsAction.pending, (state, action) => {
      state.stocksGroupsLoading = true;
    });
    builder.addCase(stocksGroupsAction.fulfilled, (state, action) => {
      state.stocksGroupsLoading = false;
      state.stocksGroups = action.payload;
      state.stocksGroupsError = null;
    });
    builder.addCase(stocksGroupsAction.rejected, (state, action) => {
      state.stocksGroupsLoading = false;
      state.stocksGroupsError = action.error.message;
    });
  },
});
//#endregion

//#region Stocks Groups Page
export const allStocksSlice = createSlice({
  name: "allStocksData",
  initialState: {
    allStocksLoading: false,
    allStocks: [],
    allStocksError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch allStocksAction
    builder.addCase(allStocksAction.pending, (state, action) => {
      state.allStocksLoading = true;
    });
    builder.addCase(allStocksAction.fulfilled, (state, action) => {
      state.allStocksLoading = false;
      state.allStocks = action.payload;
      state.allStocksError = null;
    });
    builder.addCase(allStocksAction.rejected, (state, action) => {
      state.allStocksLoading = false;
      state.allStocksError = action.error.message;
    });
  },
});
//#endregion










//#region Stocks Groups Page
export const allFundSlice = createSlice({
  name: "allFundData",
  initialState: {
    allFundLoading: false,
    allFund: [],
    allFundError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch allFundAction
    builder.addCase(allFundAction.pending, (state, action) => {
      state.allFundLoading = true;
    });
    builder.addCase(allFundAction.fulfilled, (state, action) => {
      state.allFundLoading = false;
      state.allFund = action.payload;
      state.allFundError = null;
    });
    builder.addCase(allFundAction.rejected, (state, action) => {
      state.allFundLoading = false;
      state.allFundError = action.error.message;
    });
  },
});
//#endregion
















//#region Stocks Page
export const stocksListSlice = createSlice({
  name: "stocksListData",
  initialState: {
    stocksListLoading: false,
    stocksList: [],
    stocksListError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch stocksListAction
    builder.addCase(stocksListAction.pending, (state, action) => {
      state.stocksListLoading = true;
    });
    builder.addCase(stocksListAction.fulfilled, (state, action) => {
      state.stocksListLoading = false;
      state.stocksList = action.payload;
      state.stocksListError = null;
    });
    builder.addCase(stocksListAction.rejected, (state, action) => {
      state.stocksListLoading = false;
      state.stocksListError = action.error.message;
    });
  },
});
//#endregion

//#region commodities
export const commoditiesSlice = createSlice({
  name: "commoditiesData",
  initialState: {
    commoditiesLoading: false,
    commodities: [],
    commoditiesError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch commoditiesAction
    builder.addCase(commoditiesAction.pending, (state, action) => {
      state.commoditiesLoading = true;
    });
    builder.addCase(commoditiesAction.fulfilled, (state, action) => {
      state.commoditiesLoading = false;
      state.commodities = action.payload;
      state.commoditiesError = null;
    });
    builder.addCase(commoditiesAction.rejected, (state, action) => {
      state.commoditiesLoading = false;
      state.commoditiesError = action.error.message;
    });
  },
});
//#endregion

//#region marketMap
export const marketMapSlice = createSlice({
  name: "marketMapData",
  initialState: {
    marketMapLoading: false,
    marketMap: [],
    marketMapError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch marketMapAction
    builder.addCase(marketMapAction.pending, (state, action) => {
      state.marketMapLoading = true;
    });
    builder.addCase(marketMapAction.fulfilled, (state, action) => {
      state.marketMapLoading = false;
      state.marketMap = action.payload;
      state.marketMapError = null;
    });
    builder.addCase(marketMapAction.rejected, (state, action) => {
      state.marketMapLoading = false;
      state.marketMapError = action.error.message;
    });
  },
});
//#endregion

export const CartSlice = createSlice({
  name: "CartData",
  initialState: {
    CartLoading: false,
    Cart: [],
    CartError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch CartAction
    builder.addCase(CartAction.pending, (state, action) => {
      state.CartLoading = true;
    });
    builder.addCase(CartAction.fulfilled, (state, action) => {
      state.CartLoading = false;
      state.Cart = action.payload;
      state.CartError = null;
    });
    builder.addCase(CartAction.rejected, (state, action) => {
      state.CartLoading = false;
      state.CartError = action.error.message;
    });
  },
});
//#endregion

export const AddToCartSlice = createSlice({
  name: "AddToCartData",
  initialState: {
    AddToCartLoading: false,
    AddToCart: [],
    AddToCartError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch AddToCartAction
    builder.addCase(AddToCartAction.pending, (state, action) => {
      state.AddToCartLoading = true;
    });
    builder.addCase(AddToCartAction.fulfilled, (state, action) => {
      state.AddToCartLoading = false;
      state.AddToCart = action.payload;
      state.AddToCartError = null;
    });
    builder.addCase(AddToCartAction.rejected, (state, action) => {
      state.AddToCartLoading = false;
      state.AddToCartError = action.error.message;
    });
  },
});
//#endregion

export const DeleteFromCartSlice = createSlice({
  name: "DeleteFromCardData",
  initialState: {
    DeleteFromCardLoading: false,
    DeleteFromCard: [],
    DeleteFromCardError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch deletefromcartAction
    builder.addCase(deletefromcartAction.pending, (state, action) => {
      state.DeleteFromCardLoading = true;
    });
    builder.addCase(deletefromcartAction.fulfilled, (state, action) => {
      state.DeleteFromCardLoading = false;
      state.DeleteFromCard = action.payload;
      state.DeleteFromCardError = null;
    });
    builder.addCase(deletefromcartAction.rejected, (state, action) => {
      state.DeleteFromCardLoading = false;
      state.DeleteFromCardError = action.error.message;
    });
  },
});
//#endregion

//#region filter
export const FilterSlice = createSlice({
  name: "FilterData",
  initialState: {
    FilterLoading: false,
    Filter: [],
    FilterError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch FilterAction
    builder.addCase(FilterAction.pending, (state, action) => {
      state.FilterLoading = true;
    });
    builder.addCase(FilterAction.fulfilled, (state, action) => {
      state.FilterLoading = false;
      state.Filter = action.payload;
      state.FilterError = null;
    });
    builder.addCase(FilterAction.rejected, (state, action) => {
      state.FilterLoading = false;
      state.FilterError = action.error.message;
    });
  },
});
//#endregion

export const StockHolderFilterSlice = createSlice({
  name: "StockHolderFilterData",
  initialState: {
    StockHolderFilterLoading: false,
    StockHolderFilter: [],
    StockHolderFilterError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch StockHolderFilterAction
    builder.addCase(StockHolderFilterAction.pending, (state, action) => {
      state.StockHolderFilterLoading = true;
    });
    builder.addCase(StockHolderFilterAction.fulfilled, (state, action) => {
      state.StockHolderFilterLoading = false;
      state.StockHolderFilter = action.payload;
      state.StockHolderFilterError = null;
    });
    builder.addCase(StockHolderFilterAction.rejected, (state, action) => {
      state.StockHolderFilterLoading = false;
      state.StockHolderFilterError = action.error.message;
    });
  },
});
//#endregion






export const DaramadSabetTrackingSlice = createSlice({
  name: "DaramadSabetTrackingData",
  initialState: {
    DaramadSabetTrackingLoading: false,
    DaramadSabetTracking: [],
    DaramadSabetTrackingError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch DaramadSabetTrackingAction
    builder.addCase(DaramadSabetTrackingAction.pending, (state, action) => {
      state.DaramadSabetTrackingLoading = true;
    });
    builder.addCase(DaramadSabetTrackingAction.fulfilled, (state, action) => {
      state.DaramadSabetTrackingLoading = false;
      state.DaramadSabetTracking = action.payload;
      state.DaramadSabetTrackingError = null;
    });
    builder.addCase(DaramadSabetTrackingAction.rejected, (state, action) => {
      state.DaramadSabetTrackingLoading = false;
      state.DaramadSabetTrackingError = action.error.message;
    });
  },
});
//#endregion






export const boursemainimeSlice = createSlice({
  name: "boursemainimeData",
  initialState: {
    boursemainimeLoading: false,
    boursemainime: [],
    boursemainimeError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch boursemainimeAction
    builder.addCase(boursemainimeAction.pending, (state, action) => {
      state.boursemainimeLoading = true;
    });
    builder.addCase(boursemainimeAction.fulfilled, (state, action) => {
      state.boursemainimeLoading = false;
      state.boursemainime = action.payload;
      state.boursemainimeError = null;
    });
    builder.addCase(boursemainimeAction.rejected, (state, action) => {
      state.boursemainimeLoading = false;
      state.boursemainimeError = action.error.message;
    });
  },
});
//#endregion









export const AssemblyFilterSlice = createSlice({
  name: "AssemblyFilterData",
  initialState: {
    AssemblyFilterLoading: false,
    AssemblyFilter: [],
    AssemblyFilterError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch AssemblyFilterAction
    builder.addCase(AssemblyFilterAction.pending, (state, action) => {
      state.AssemblyFilterLoading = true;
    });
    builder.addCase(AssemblyFilterAction.fulfilled, (state, action) => {
      state.AssemblyFilterLoading = false;
      state.AssemblyFilter = action.payload;
      state.AssemblyFilterError = null;
    });
    builder.addCase(AssemblyFilterAction.rejected, (state, action) => {
      state.AssemblyFilterLoading = false;
      state.AssemblyFilterError = action.error.message;
    });
  },
});
//#endregion

export const MonthlyFilterSlice = createSlice({
  name: "MonthlyFilterData",
  initialState: {
    MonthlyFilterLoading: false,
    MonthlyFilter: [],
    MonthlyFilterError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch MonthlyFilterAction
    builder.addCase(MonthlyFilterAction.pending, (state, action) => {
      state.MonthlyFilterLoading = true;
    });
    builder.addCase(MonthlyFilterAction.fulfilled, (state, action) => {
      state.MonthlyFilterLoading = false;
      state.MonthlyFilter = action.payload;
      state.MonthlyFilterError = null;
    });
    builder.addCase(MonthlyFilterAction.rejected, (state, action) => {
      state.MonthlyFilterLoading = false;
      state.MonthlyFilterError = action.error.message;
    });
  },
});
//#endregion

export const MarginFilterSlice = createSlice({
  name: "MarginFilterData",
  initialState: {
    MarginFilterLoading: false,
    MarginFilter: [],
    MarginFilterError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch MarginFilterAction
    builder.addCase(MarginFilterAction.pending, (state, action) => {
      state.MarginFilterLoading = true;
    });
    builder.addCase(MarginFilterAction.fulfilled, (state, action) => {
      state.MarginFilterLoading = false;
      state.MarginFilter = action.payload;
      state.MarginFilterError = null;
    });
    builder.addCase(MarginFilterAction.rejected, (state, action) => {
      state.MarginFilterLoading = false;
      state.MarginFilterError = action.error.message;
    });
  },
});
//#endregion

export const PEPSFilterSlice = createSlice({
  name: "PEPSFilterData",
  initialState: {
    PEPSFilterLoading: false,
    PEPSFilter: [],
    PEPSFilterError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch PEPSFilterAction
    builder.addCase(PEPSFilterAction.pending, (state, action) => {
      state.PEPSFilterLoading = true;
    });
    builder.addCase(PEPSFilterAction.fulfilled, (state, action) => {
      state.PEPSFilterLoading = false;
      state.PEPSFilter = action.payload;
      state.PEPSFilterError = null;
    });
    builder.addCase(PEPSFilterAction.rejected, (state, action) => {
      state.PEPSFilterLoading = false;
      state.PEPSFilterError = action.error.message;
    });
  },
});
//#endregion

export const PriceActionFilterSlice = createSlice({
  name: "PriceActionFilterData",
  initialState: {
    PriceActionFilterLoading: false,
    PriceActionFilter: [],
    PriceActionFilterError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch PriceActionFilterAction
    builder.addCase(PriceActionFilterAction.pending, (state, action) => {
      state.PriceActionFilterLoading = true;
    });
    builder.addCase(PriceActionFilterAction.fulfilled, (state, action) => {
      state.PriceActionFilterLoading = false;
      state.PriceActionFilter = action.payload;
      state.PriceActionFilterError = null;
    });
    builder.addCase(PriceActionFilterAction.rejected, (state, action) => {
      state.PriceActionFilterLoading = false;
      state.PriceActionFilterError = action.error.message;
    });
  },
});
//#endregion

export const ChartPatternsFilterSlice = createSlice({
  name: "ChartPatternsFilterData",
  initialState: {
    ChartPatternsFilterLoading: false,
    ChartPatternsFilter: [],
    ChartPatternsFilterError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch ChartPatternsFilterAction
    builder.addCase(ChartPatternsFilterAction.pending, (state, action) => {
      state.ChartPatternsFilterLoading = true;
    });
    builder.addCase(ChartPatternsFilterAction.fulfilled, (state, action) => {
      state.ChartPatternsFilterLoading = false;
      state.ChartPatternsFilter = action.payload;
      state.ChartPatternsFilterError = null;
    });
    builder.addCase(ChartPatternsFilterAction.rejected, (state, action) => {
      state.ChartPatternsFilterLoading = false;
      state.ChartPatternsFilterError = action.error.message;
    });
  },
});
//#endregion

export const MonthReportSlice = createSlice({
  name: "MonthReportData",
  initialState: {
    MonthReportLoading: false,
    MonthReport: [],
    MonthReportError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch MonthReportAction
    builder.addCase(MonthReportAction.pending, (state, action) => {
      state.MonthReportLoading = true;
    });
    builder.addCase(MonthReportAction.fulfilled, (state, action) => {
      state.MonthReportLoading = false;
      state.MonthReport = action.payload;
      state.MonthReportError = null;
    });
    builder.addCase(MonthReportAction.rejected, (state, action) => {
      state.MonthReportLoading = false;
      state.MonthReportError = action.error.message;
    });
  },
});
//#endregion








export const line_trackingSlice = createSlice({
  name: "line_trackingData",
  initialState: {
    line_trackingLoading: false,
    line_tracking: [],
    line_trackingError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch line_trackingAction
    builder.addCase(line_trackingAction.pending, (state, action) => {
      state.line_trackingLoading = true;
    });
    builder.addCase(line_trackingAction.fulfilled, (state, action) => {
      state.line_trackingLoading = false;
      state.line_tracking = action.payload;
      state.line_trackingError = null;
    });
    builder.addCase(line_trackingAction.rejected, (state, action) => {
      state.line_trackingLoading = false;
      state.line_trackingError = action.error.message;
    });
  },
});
//#endregion
















export const singlecommoSlice = createSlice({
  name: "singlecommoData",
  initialState: {
    singlecommoLoading: false,
    singlecommo: [],
    singlecommoError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch singlecommoAction
    builder.addCase(singlecommoAction.pending, (state, action) => {
      state.singlecommoLoading = true;
    });
    builder.addCase(singlecommoAction.fulfilled, (state, action) => {
      state.singlecommoLoading = false;
      state.singlecommo = action.payload;
      state.singlecommoError = null;
    });
    builder.addCase(singlecommoAction.rejected, (state, action) => {
      state.singlecommoLoading = false;
      state.singlecommoError = action.error.message;
    });
  },
});
//#endregion





export const MonthReport1Slice = createSlice({
  name: "MonthReport1Data",
  initialState: {
    MonthReport1Loading: false,
    MonthReport1: [],
    MonthReport1Error: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch MonthReport1Action
    builder.addCase(MonthReport1Action.pending, (state, action) => {
      state.MonthReport1Loading = true;
    });
    builder.addCase(MonthReport1Action.fulfilled, (state, action) => {
      state.MonthReport1Loading = false;
      state.MonthReport1 = action.payload;
      state.MonthReport1Error = null;
    });
    builder.addCase(MonthReport1Action.rejected, (state, action) => {
      state.MonthReport1Loading = false;
      state.MonthReport1Error = action.error.message;
    });
  },
});
//#endregion

export const UserThemeSlice = createSlice({
  name: "UserThemeData",
  initialState: {
    UserThemeLoading: false,
    UserTheme: [],
    UserThemeError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch UserThemeAction
    builder.addCase(UserThemeAction.pending, (state, action) => {
      state.UserThemeLoading = true;
    });
    builder.addCase(UserThemeAction.fulfilled, (state, action) => {
      state.UserThemeLoading = false;
      state.UserTheme = action.payload;
      state.UserThemeError = null;
    });
    builder.addCase(UserThemeAction.rejected, (state, action) => {
      state.UserThemeLoading = false;
      state.UserThemeError = action.error.message;
    });
  },
});
//#endregion









export const ComingSoonPageSlice = createSlice({
  name: "ComingSoonPageData",
  initialState: {
    ComingSoonPageLoading: false,
    ComingSoonPage: [],
    ComingSoonPageError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch ComingSoonPageAction
    builder.addCase(ComingSoonPageAction.pending, (state, action) => {
      state.ComingSoonPageLoading = true;
    });
    builder.addCase(ComingSoonPageAction.fulfilled, (state, action) => {
      state.ComingSoonPageLoading = false;
      state.ComingSoonPage = action.payload;
      state.ComingSoonPageError = null;
    });
    builder.addCase(ComingSoonPageAction.rejected, (state, action) => {
      state.ComingSoonPageLoading = false;
      state.ComingSoonPageError = action.error.message;
    });
  },
});
//#endregion

