import { createSlice } from "@reduxjs/toolkit";

import {
  AdminUserAction,
  AdminChangeUserSubscriptionAction,
  AdminURLSAction,
  AdminMonthlyAction,
  AdminMonthlySingleAction,
  AdminMonthlyDeleteAction,
  AdminSeasonAction,
  AdminRealStateAction,
  AdminAssemblyAction,
  AdminBudgetAction,
  AdminAddStockAction,
  AdmindeleteStockAction,
  AdminupdateStockAction,
  AdminSeasonSingleAction,
  AdminmainAction,
  AdminBalanceAction,
  AdminBalanceSingleAction,
  AdminSeasonUpdateAction,
  AdminSeasonDeleteYearAction,
  AdminSeasonDeleteSubjectAction,
  AdminSeasonAddAction,
  AdminGoldDollarAction,
  AdminTecnicalChartAction,
  AdminAssemblyAddAction,
  AdminSeasonDetailAction,
  AdminSeasonDetailCommodityAction,
  AdminSeasonTypeAction,
} from "./AdminAction";

export const AdminUserSlice = createSlice({
  name: "AdminUserData",
  initialState: {
    AdminUserLoading: false,
    AdminUser: [],
    AdminUserError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminUserAction
    builder.addCase(AdminUserAction.pending, (state, action) => {
      state.AdminUserLoading = true;
    });
    builder.addCase(AdminUserAction.fulfilled, (state, action) => {
      state.AdminUserLoading = false;
      state.AdminUser = action.payload;
      state.AdminUserError = null;
    });
    builder.addCase(AdminUserAction.rejected, (state, action) => {
      state.AdminUserLoading = false;
      state.AdminUserError = action.error.message;
    });
  },
});

export const AdminChangeUserSubscriptionSlice = createSlice({
  name: "AdminChangeUserSubscriptionData",
  initialState: {
    AdminChangeUserSubscriptionLoading: false,
    AdminChangeUserSubscription: [],
    AdminChangeUserSubscriptionError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminChangeUserSubscriptionAction
    builder.addCase(
      AdminChangeUserSubscriptionAction.pending,
      (state, action) => {
        state.AdminChangeUserSubscriptionLoading = true;
      }
    );
    builder.addCase(
      AdminChangeUserSubscriptionAction.fulfilled,
      (state, action) => {
        state.AdminChangeUserSubscriptionLoading = false;
        state.AdminChangeUserSubscription = action.payload;
        state.AdminChangeUserSubscriptionError = null;
      }
    );
    builder.addCase(
      AdminChangeUserSubscriptionAction.rejected,
      (state, action) => {
        state.AdminChangeUserSubscriptionLoading = false;
        state.AdminChangeUserSubscriptionError = action.error.message;
      }
    );
  },
});

export const AdminURLSSlice = createSlice({
  name: "AdminURLSData",
  initialState: {
    AdminURLSLoading: false,
    AdminURLS: [],
    AdminURLSError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminURLSAction
    builder.addCase(AdminURLSAction.pending, (state, action) => {
      state.AdminURLSLoading = true;
    });
    builder.addCase(AdminURLSAction.fulfilled, (state, action) => {
      state.AdminURLSLoading = false;
      state.AdminURLS = action.payload;
      state.AdminURLSError = null;
    });
    builder.addCase(AdminURLSAction.rejected, (state, action) => {
      state.AdminURLSLoading = false;
      state.AdminURLSError = action.error.message;
    });
  },
});

export const AdminMonthlySlice = createSlice({
  name: "AdminMonthlyData",
  initialState: {
    AdminMonthlyLoading: false,
    AdminMonthly: [],
    AdminMonthlyError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminMonthlyAction
    builder.addCase(AdminMonthlyAction.pending, (state, action) => {
      state.AdminMonthlyLoading = true;
    });
    builder.addCase(AdminMonthlyAction.fulfilled, (state, action) => {
      state.AdminMonthlyLoading = false;
      state.AdminMonthly = action.payload;
      state.AdminMonthlyError = null;
    });
    builder.addCase(AdminMonthlyAction.rejected, (state, action) => {
      state.AdminMonthlyLoading = false;
      state.AdminMonthlyError = action.error.message;
    });
  },
});

export const AdminMonthlySingleSlice = createSlice({
  name: "AdminMonthlySingleData",
  initialState: {
    AdminMonthlySingleLoading: false,
    AdminMonthlySingle: [],
    AdminMonthlySingleError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminMonthlySingleAction
    builder.addCase(AdminMonthlySingleAction.pending, (state, action) => {
      state.AdminMonthlySingleLoading = true;
    });
    builder.addCase(AdminMonthlySingleAction.fulfilled, (state, action) => {
      state.AdminMonthlySingleLoading = false;
      state.AdminMonthlySingle = action.payload;
      state.AdminMonthlySingleError = null;
    });
    builder.addCase(AdminMonthlySingleAction.rejected, (state, action) => {
      state.AdminMonthlySingleLoading = false;
      state.AdminMonthlySingleError = action.error.message;
    });
  },
});

export const AdminSeasonUpdateSlice = createSlice({
  name: "AdminSeasonUpdateData",
  initialState: {
    AdminSeasonUpdateLoading: false,
    AdminSeasonUpdate: [],
    AdminSeasonUpdateError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminSeasonUpdateAction
    builder.addCase(AdminSeasonUpdateAction.pending, (state, action) => {
      state.AdminSeasonUpdateLoading = true;
    });
    builder.addCase(AdminSeasonUpdateAction.fulfilled, (state, action) => {
      state.AdminSeasonUpdateLoading = false;
      state.AdminSeasonUpdate = action.payload;
      state.AdminSeasonUpdateError = null;
    });
    builder.addCase(AdminSeasonUpdateAction.rejected, (state, action) => {
      state.AdminSeasonUpdateLoading = false;
      state.AdminSeasonUpdateError = action.error.message;
    });
  },
});

export const AdminSeasonDeleteYearSlice = createSlice({
  name: "AdminSeasonDeleteYearData",
  initialState: {
    AdminSeasonDeleteYearLoading: false,
    AdminSeasonDeleteYear: [],
    AdminSeasonDeleteYearError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminSeasonDeleteYearAction
    builder.addCase(AdminSeasonDeleteYearAction.pending, (state, action) => {
      state.AdminSeasonDeleteYearLoading = true;
    });
    builder.addCase(AdminSeasonDeleteYearAction.fulfilled, (state, action) => {
      state.AdminSeasonDeleteYearLoading = false;
      state.AdminSeasonDeleteYear = action.payload;
      state.AdminSeasonDeleteYearError = null;
    });
    builder.addCase(AdminSeasonDeleteYearAction.rejected, (state, action) => {
      state.AdminSeasonDeleteYearLoading = false;
      state.AdminSeasonDeleteYearError = action.error.message;
    });
  },
});

export const AdminSeasonDeleteSubjectSlice = createSlice({
  name: "AdminSeasonDeleteSubjectData",
  initialState: {
    AdminSeasonDeleteSubjectLoading: false,
    AdminSeasonDeleteSubject: [],
    AdminSeasonDeleteSubjectError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminSeasonDeleteSubjectAction
    builder.addCase(AdminSeasonDeleteSubjectAction.pending, (state, action) => {
      state.AdminSeasonDeleteSubjectLoading = true;
    });
    builder.addCase(
      AdminSeasonDeleteSubjectAction.fulfilled,
      (state, action) => {
        state.AdminSeasonDeleteSubjectLoading = false;
        state.AdminSeasonDeleteSubject = action.payload;
        state.AdminSeasonDeleteSubjectError = null;
      }
    );
    builder.addCase(
      AdminSeasonDeleteSubjectAction.rejected,
      (state, action) => {
        state.AdminSeasonDeleteSubjectLoading = false;
        state.AdminSeasonDeleteSubjectError = action.error.message;
      }
    );
  },
});

export const AdminSeasonAddSlice = createSlice({
  name: "AdminSeasonAddData",
  initialState: {
    AdminSeasonAddLoading: false,
    AdminSeasonAdd: [],
    AdminSeasonAddError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminSeasonAddAction
    builder.addCase(AdminSeasonAddAction.pending, (state, action) => {
      state.AdminSeasonAddLoading = true;
    });
    builder.addCase(AdminSeasonAddAction.fulfilled, (state, action) => {
      state.AdminSeasonAddLoading = false;
      state.AdminSeasonAdd = action.payload;
      state.AdminSeasonAddError = null;
    });
    builder.addCase(AdminSeasonAddAction.rejected, (state, action) => {
      state.AdminSeasonAddLoading = false;
      state.AdminSeasonAddError = action.error.message;
    });
  },
});

export const AdminAssemblyAddSlice = createSlice({
  name: "AdminAssemblyAddData",
  initialState: {
    AdminAssemblyAddLoading: false,
    AdminAssemblyAdd: [],
    AdminAssemblyAddError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminAssemblyAddAction
    builder.addCase(AdminAssemblyAddAction.pending, (state, action) => {
      state.AdminAssemblyAddLoading = true;
    });
    builder.addCase(AdminAssemblyAddAction.fulfilled, (state, action) => {
      state.AdminAssemblyAddLoading = false;
      state.AdminAssemblyAdd = action.payload;
      state.AdminAssemblyAddError = null;
    });
    builder.addCase(AdminAssemblyAddAction.rejected, (state, action) => {
      state.AdminAssemblyAddLoading = false;
      state.AdminAssemblyAddError = action.error.message;
    });
  },
});

export const AdminSeasonSlice = createSlice({
  name: "AdminSeasonData",
  initialState: {
    AdminSeasonLoading: false,
    AdminSeason: [],
    AdminSeasonError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminSeasonAction
    builder.addCase(AdminSeasonAction.pending, (state, action) => {
      state.AdminSeasonLoading = true;
    });
    builder.addCase(AdminSeasonAction.fulfilled, (state, action) => {
      state.AdminSeasonLoading = false;
      state.AdminSeason = action.payload;
      state.AdminSeasonError = null;
    });
    builder.addCase(AdminSeasonAction.rejected, (state, action) => {
      state.AdminSeasonLoading = false;
      state.AdminSeasonError = action.error.message;
    });
  },
});

export const AdminSeasonDetailSlice = createSlice({
  name: "AdminSeasonDetailData",
  initialState: {
    AdminSeasonDetailLoading: false,
    AdminSeasonDetail: [],
    AdminSeasonDetailError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminSeasonDetailAction
    builder.addCase(AdminSeasonDetailAction.pending, (state, action) => {
      state.AdminSeasonDetailLoading = true;
    });
    builder.addCase(AdminSeasonDetailAction.fulfilled, (state, action) => {
      state.AdminSeasonDetailLoading = false;
      state.AdminSeasonDetail = action.payload;
      state.AdminSeasonDetailError = null;
    });
    builder.addCase(AdminSeasonDetailAction.rejected, (state, action) => {
      state.AdminSeasonDetailLoading = false;
      state.AdminSeasonDetailError = action.error.message;
    });
  },
});

export const AdminSeasonDetailCommoditySlice = createSlice({
  name: "AdminSeasonDetailCommodityData",
  initialState: {
    AdminSeasonDetailCommodityLoading: false,
    AdminSeasonDetailCommodity: [],
    AdminSeasonDetailCommodityError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminSeasonDetailCommodityAction
    builder.addCase(
      AdminSeasonDetailCommodityAction.pending,
      (state, action) => {
        state.AdminSeasonDetailCommodityLoading = true;
      }
    );
    builder.addCase(
      AdminSeasonDetailCommodityAction.fulfilled,
      (state, action) => {
        state.AdminSeasonDetailCommodityLoading = false;
        state.AdminSeasonDetailCommodity = action.payload;
        state.AdminSeasonDetailCommodityError = null;
      }
    );
    builder.addCase(
      AdminSeasonDetailCommodityAction.rejected,
      (state, action) => {
        state.AdminSeasonDetailCommodityLoading = false;
        state.AdminSeasonDetailCommodityError = action.error.message;
      }
    );
  },
});

export const AdminSeasonTypeSlice = createSlice({
  name: "AdminSeasonTypeData",
  initialState: {
    AdminSeasonTypeLoading: false,
    AdminSeasonType: [],
    AdminSeasonTypeError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminSeasonTypeAction
    builder.addCase(AdminSeasonTypeAction.pending, (state, action) => {
      state.AdminSeasonTypeLoading = true;
    });
    builder.addCase(AdminSeasonTypeAction.fulfilled, (state, action) => {
      state.AdminSeasonTypeLoading = false;
      state.AdminSeasonType = action.payload;
      state.AdminSeasonTypeError = null;
    });
    builder.addCase(AdminSeasonTypeAction.rejected, (state, action) => {
      state.AdminSeasonTypeLoading = false;
      state.AdminSeasonTypeError = action.error.message;
    });
  },
});

export const AdminSeasonSingleSlice = createSlice({
  name: "AdminSeasonSingleData",
  initialState: {
    AdminSeasonSingleLoading: false,
    AdminSeasonSingle: [],
    AdminSeasonSingleError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminSeasonSingleAction
    builder.addCase(AdminSeasonSingleAction.pending, (state, action) => {
      state.AdminSeasonSingleLoading = true;
    });
    builder.addCase(AdminSeasonSingleAction.fulfilled, (state, action) => {
      state.AdminSeasonSingleLoading = false;
      state.AdminSeasonSingle = action.payload;
      state.AdminSeasonSingleError = null;
    });
    builder.addCase(AdminSeasonSingleAction.rejected, (state, action) => {
      state.AdminSeasonSingleLoading = false;
      state.AdminSeasonSingleError = action.error.message;
    });
  },
});

export const AdminBalanceSlice = createSlice({
  name: "AdminBalanceData",
  initialState: {
    AdminBalanceLoading: false,
    AdminBalance: [],
    AdminBalanceError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminBalanceAction
    builder.addCase(AdminBalanceAction.pending, (state, action) => {
      state.AdminBalanceLoading = true;
    });
    builder.addCase(AdminBalanceAction.fulfilled, (state, action) => {
      state.AdminBalanceLoading = false;
      state.AdminBalance = action.payload;
      state.AdminBalanceError = null;
    });
    builder.addCase(AdminBalanceAction.rejected, (state, action) => {
      state.AdminBalanceLoading = false;
      state.AdminBalanceError = action.error.message;
    });

    // fetch AdminBalanceSingleAction
    builder.addCase(AdminBalanceSingleAction.pending, (state, action) => {
      state.AdminBalanceLoading = true;
    });
    builder.addCase(AdminBalanceSingleAction.fulfilled, (state, action) => {
      state.AdminBalanceLoading = false;
      state.AdminBalance = action.payload;
      state.AdminBalanceError = null;
    });
    builder.addCase(AdminBalanceSingleAction.rejected, (state, action) => {
      state.AdminBalance = false;
      state.AdminBalanceError = action.error.message;
    });
  },
});

export const AdminMonthlyDeleteSlice = createSlice({
  name: "AdminMonthlyDeleteData",
  initialState: {
    AdminMonthlyDeleteLoading: false,
    AdminMonthlyDelete: [],
    AdminMonthlyDeleteError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminMonthlyDeleteAction
    builder.addCase(AdminMonthlyDeleteAction.pending, (state, action) => {
      state.AdminMonthlyDeleteLoading = true;
    });
    builder.addCase(AdminMonthlyDeleteAction.fulfilled, (state, action) => {
      state.AdminMonthlyDeleteLoading = false;
      state.AdminMonthlyDelete = action.payload;
      state.AdminMonthlyDeleteError = null;
    });
    builder.addCase(AdminMonthlyDeleteAction.rejected, (state, action) => {
      state.AdminMonthlyDeleteLoading = false;
      state.AdminMonthlyDeleteError = action.error.message;
    });
  },
});

export const AdminAssemblySlice = createSlice({
  name: "AdminAssemblyData",
  initialState: {
    AdminAssemblyLoading: false,
    AdminAssembly: [],
    AdminAssemblyError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminAssemblyAction
    builder.addCase(AdminAssemblyAction.pending, (state, action) => {
      state.AdminAssemblyLoading = true;
    });
    builder.addCase(AdminAssemblyAction.fulfilled, (state, action) => {
      state.AdminAssemblyLoading = false;
      state.AdminAssembly = action.payload;
      state.AdminAssemblyError = null;
    });
    builder.addCase(AdminAssemblyAction.rejected, (state, action) => {
      state.AdminAssemblyLoading = false;
      state.AdminAssemblyError = action.error.message;
    });
  },
});

export const AdminBudgetSlice = createSlice({
  name: "AdminBudgetData",
  initialState: {
    AdminBudgetLoading: false,
    AdminBudget: [],
    AdminBudgetError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminBudgetAction
    builder.addCase(AdminBudgetAction.pending, (state, action) => {
      state.AdminBudgetLoading = true;
    });
    builder.addCase(AdminBudgetAction.fulfilled, (state, action) => {
      state.AdminBudgetLoading = false;
      state.AdminBudget = action.payload;
      state.AdminBudgetError = null;
    });
    builder.addCase(AdminBudgetAction.rejected, (state, action) => {
      state.AdminBudgetLoading = false;
      state.AdminBudgetError = action.error.message;
    });
  },
});

export const AdminRealStateSlice = createSlice({
  name: "AdminRealStateData",
  initialState: {
    AdminRealStateLoading: false,
    AdminRealState: [],
    AdminRealStateError: null,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // state.serverMessage = "";
    },
  },
  extraReducers: (builder) => {
    // fetch AdminRealStateAction
    builder.addCase(AdminRealStateAction.pending, (state, action) => {
      state.AdminRealStateLoading = true;
    });
    builder.addCase(AdminRealStateAction.fulfilled, (state, action) => {
      state.AdminRealStateLoading = false;
      state.AdminRealState = action.payload;
      state.AdminRealStateError = null;
    });
    builder.addCase(AdminRealStateAction.rejected, (state, action) => {
      state.AdminRealStateLoading = false;
      state.AdminRealStateError = action.error.message;
    });
  },
});

export const AdminAddStockSlice = createSlice({
  name: "AdminAddStockData",
  initialState: {
    AdminAddStockLoading: false,
    AdminAddStock: [],
    AdminAddStockError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch AdminAddStockAction
    builder.addCase(AdminAddStockAction.pending, (state, action) => {
      state.AdminAddStockLoading = true;
    });
    builder.addCase(AdminAddStockAction.fulfilled, (state, action) => {
      state.AdminAddStockLoading = false;
      state.AdminAddStock = action.payload;
      state.AdminAddStockError = null;
    });
    builder.addCase(AdminAddStockAction.rejected, (state, action) => {
      state.AdminAddStockLoading = false;
      state.AdminAddStockError = action.error.message;
    });
  },
});
//#endregion

export const AdmindeleteStockSlice = createSlice({
  name: "AdmindeleteStockData",
  initialState: {
    AdmindeleteStockLoading: false,
    AdmindeleteStock: [],
    AdmindeleteStockError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch AdmindeleteStockAction
    builder.addCase(AdmindeleteStockAction.pending, (state, action) => {
      state.AdmindeleteStockLoading = true;
    });
    builder.addCase(AdmindeleteStockAction.fulfilled, (state, action) => {
      state.AdmindeleteStockLoading = false;
      state.AdmindeleteStock = action.payload;
      state.AdmindeleteStockError = null;
    });
    builder.addCase(AdmindeleteStockAction.rejected, (state, action) => {
      state.AdmindeleteStockLoading = false;
      state.AdmindeleteStockError = action.error.message;
    });
  },
});
//#endregion

export const AdminupdateStockSlice = createSlice({
  name: "AdminupdateStockData",
  initialState: {
    AdminupdateStockLoading: false,
    AdminupdateStock: [],
    AdminupdateStockError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch AdminupdateStockAction
    builder.addCase(AdminupdateStockAction.pending, (state, action) => {
      state.AdminupdateStockLoading = true;
    });
    builder.addCase(AdminupdateStockAction.fulfilled, (state, action) => {
      state.AdminupdateStockLoading = false;
      state.AdminupdateStock = action.payload;
      state.AdminupdateStockError = null;
    });
    builder.addCase(AdminupdateStockAction.rejected, (state, action) => {
      state.AdminupdateStockLoading = false;
      state.AdminupdateStockError = action.error.message;
    });
  },
});
//#endregion

export const AdminmainSlice = createSlice({
  name: "AdminmainData",
  initialState: {
    AdminmainLoading: false,
    Adminmain: [],
    AdminmainError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch AdminmainAction
    builder.addCase(AdminmainAction.pending, (state, action) => {
      state.AdminmainLoading = true;
    });
    builder.addCase(AdminmainAction.fulfilled, (state, action) => {
      state.AdminmainLoading = false;
      state.Adminmain = action.payload;
      state.AdminmainError = null;
    });
    builder.addCase(AdminmainAction.rejected, (state, action) => {
      state.AdminmainLoading = false;
      state.AdminmainError = action.error.message;
    });
  },
});
//#endregion

export const AdminGoldDollarSlice = createSlice({
  name: "AdminGoldDollarData",
  initialState: {
    AdminGoldDollarLoading: false,
    AdminGoldDollar: [],
    AdminGoldDollarError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch AdminGoldDollarAction
    builder.addCase(AdminGoldDollarAction.pending, (state, action) => {
      state.AdminGoldDollarLoading = true;
    });
    builder.addCase(AdminGoldDollarAction.fulfilled, (state, action) => {
      state.AdminGoldDollarLoading = false;
      state.AdminGoldDollar = action.payload;
      state.AdminGoldDollarError = null;
    });
    builder.addCase(AdminGoldDollarAction.rejected, (state, action) => {
      state.AdminGoldDollarLoading = false;
      state.AdminGoldDollarError = action.error.message;
    });
  },
});
//#endregion

export const AdminTecnicalChartSlice = createSlice({
  name: "AdminTecnicalChartData",
  initialState: {
    AdminTecnicalChartLoading: false,
    AdminTecnicalChart: [],
    AdminTecnicalChartError: null,
  },
  reducers: {
    clearHandleError: (state, action) => {},
  },
  extraReducers: (builder) => {
    // fetch AdminTecnicalChartAction
    builder.addCase(AdminTecnicalChartAction.pending, (state, action) => {
      state.AdminTecnicalChartLoading = true;
    });
    builder.addCase(AdminTecnicalChartAction.fulfilled, (state, action) => {
      state.AdminTecnicalChartLoading = false;
      state.AdminTecnicalChart = action.payload;
      state.AdminTecnicalChartError = null;
    });
    builder.addCase(AdminTecnicalChartAction.rejected, (state, action) => {
      state.AdminTecnicalChartLoading = false;
      state.AdminTecnicalChartError = action.error.message;
    });
  },
});
//#endregion
