import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../../config.json";
const url = `${configData.ApiAddress}`;

//#region User --> UserWatchlist
export const UserWatchlistAction = createAsyncThunk(
  "users/allwatchlist/",
  async (data) => {
    return axios
      .get(`${url}/users/allwatchlist/`, {
        params: {},
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region User --> UseraddWatchlist
export const UserAddWatchlistAction = createAsyncThunk(
  "users/addwatchlist/",
  async (data) => {
    return axios
      .get(`${url}/users/addwatchlist/`, {
        params: {
          name: data.name,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region User --> UseraddWatchlist
export const UserDeleteWatchlistAction = createAsyncThunk(
  "users/Deletewatchlist/",
  async (data) => {
    return axios
      .get(`${url}/users/Deletewatchlist/`, {
        params: {
          name: data.name,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

export const UserSingleWatchlistAction = createAsyncThunk(
  "users/singlewatchlist/",
  async (data) => {
    console.log(data.slug.stocks_slug);
    return axios
      .get(`${url}/users/singlewatchlist/`, {
        params: {
          slug: data.slug.stocks_slug,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

export const UserSingle1WatchlistAction = createAsyncThunk(
  "users/singlewatchlist/",

  async (data) => {
    return axios
      .get(`${url}/users/singlewatchlist/`, {
        params: {
          slug: data.slug.stocks_slug,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

export const UserAddstockWatchlistAction = createAsyncThunk(
  "users/AddstockWatchlist/",
  async (data) => {
    return axios
      .get(`${url}/users/AddstockWatchlist/`, {
        params: {
          stock: data.stock,
          slug: data.slug.stocks_slug,
        },
      })
      .then((response) => response.data);
  }
);

export const UserDeletestockWatchlistAction = createAsyncThunk(
  "users/DeletestockWatchlist/",
  async (data) => {
    console.log(data.stock.name);
    return axios

      .get(`${url}/users/DeletestockWatchlist/`, {
        params: {
          stock: data.stock.name,
          slug: data.slug.stocks_slug,
        },
      })
      .then((response) => response.data);
  }
);

//#region User --> UserWatchlist
export const UserAlarmsAction = createAsyncThunk(
  "/bourse/main/allalarms/",
  async (data) => {
    return axios
      .get(`${url}/bourse/main/allalarms/`, {
        params: {},
      })
      .then((response) => response.data);
  }
);
//#endregion

export const UserAddAlarmsAction = createAsyncThunk(
  "main/addalarm/",
  async (data) => {
    return axios
      .get(`${url}/bourse/main/addalarm/`, {
        params: {
          email: data.info.email,
          stock: data.stock,
          user_profit_limit_price: data.user_profit_limit_price,
          user_stop_loss_price: data.user_stop_loss_price,
        },
      })
      .then((response) => response.data);
  }
);

//#region User --> UserWatchlist
export const UserDeleteAlarmsAction = createAsyncThunk(
  "/bourse/main/deletealarm/",
  async (data) => {
    console.log(data);

    return axios
      .get(`${url}/bourse/main/deletealarm/`, {
        params: {
          email: data.info.email,
          stock: data.stock,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region User --> UserWatchlist
export const UserAddCommentAction = createAsyncThunk(
  "users/AddComment/",
  async (data) => {
    return axios
      .get(`${url}/users/AddComment/`, {
        params: {
          email: data.info.email,
          message: data.message,
          slug: data.slug.stocks_slug,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region User --> UserWatchlist
export const UserAddLikeAction = createAsyncThunk(
  "users/Addlike/",
  async (data) => {
    return axios
      .get(`${url}/users/Addlike/`, {
        params: {
          name: data.info.name,
          id: data.id,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region User --> UserWatchlist
export const UserAdddisLikeAction = createAsyncThunk(
  "users/Adddislike/",
  async (data) => {
    return axios
      .get(`${url}/users/Adddislike/`, {
        params: {
          name: data.info.name,
          id: data.id,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region User --> UserWatchlist
export const UserReportCommentAction = createAsyncThunk(
  "users/ReportComment/",
  async (data) => {
    return axios
      .get(`${url}/users/ReportComment/`, {
        params: {
          name: data.info.name,
          comment_id: data.report_comment.id,
          report_Message: data.report_Message,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion
