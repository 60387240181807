import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { HashRouter as Router, useLocation } from "react-router-dom"; // Use HashRouter

import { Provider } from "react-redux";
import store from "./app/store";
import "bootstrap/dist/css/bootstrap.min.css";
import axiosHeaderConfig from "./redux/config";
import ThemeProvider from "./theme/ThemeProvider";
import { initializeAnalytics, logPageView } from "./analytics"; // Import analytics functions

//Initialize Google Analytics


initializeAnalytics();

// Component to log page views
const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

try {
  const info = JSON.parse(localStorage.getItem("userInfoData"));
  if (info) {
    axiosHeaderConfig(info.token);
  }
} catch {}
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <Provider store={store}>
      <ThemeProvider>
        <Router>
          <TrackPageViews /> {/* Add TrackPageViews here */}
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  </React.Fragment>
);

// root.render(
//   <React.Fragment>
//     <Provider store={store}>
//       <ThemeProvider>
//         <App />
//       </ThemeProvider>
//     </Provider>
//   </React.Fragment>
// );
