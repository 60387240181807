import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../../config.json";
const url = `${configData.ApiAddress}/bourse/stock`;

//#region Stock --> Monthly Report
export const monthlyReportAction = createAsyncThunk(
  "fundamental/monthlyReport",
  async (data) => {
    return axios
      .get(`${url}/fundamental/month/${data.slug.stocks_slug}`, {
        params: { year: data.year },
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region Stock --> Seasonal Report
export const seasonalReportAction = createAsyncThunk(
  "fundamental/seasonalReport",
  async (data) => {
    return axios
      .get(`${url}/fundamental/season/${data.slug.stocks_slug}`, {
        params: {
          season: data.season,
          period: data.period,
          consolidation: data.consolidation,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

export const CommodityMavadAvalieAction = createAsyncThunk(
  "fundamental/CommodityMavadAvalie",
  async (data) => {
    return axios
      .get(`${url}/fundamental/CommodityMavadAvalie/${data.slug.stocks_slug}`, {
        params: {
          season: data.season,
          period: data.period,
          consolidation: data.consolidation,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region Stock --> Last Seasonal Report
export const lastSeasonalReportAction = createAsyncThunk(
  "fundamental/lastseason",
  async (data) => {
    return axios
      .get(`${url}/fundamental/lastseason/${data.stocks_slug}`, {
        params: {
          year: data.year,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region Stock --> Balance Report
export const balanceReportAction = createAsyncThunk(
  "fundamental/balanceReport",
  async (data) => {
    // return axios.get(`${url}/fundamental/balance/${data.slug}`).then((response) => response.data);

    return axios
      .get(`${url}/fundamental/balance/${data.slug}`, {
        params: {
          year: data.year,
          quarter: data.quarter,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region Stock --> MarginAction
export const marginReportAction = createAsyncThunk(
  "fundamental/marginReport",
  async (data) => {
    return axios
      .get(`${url}/fundamental/margin/${data.slug.stocks_slug}`, {
        params: { year: data.year },
      })
      .then((response) => response.data);
  }
);
//#endregion

//#region Stock --> assemblyReport Action
export const assemblyReportAction = createAsyncThunk(
  "fundamental/assembly",
  async (slug) => {
    return axios
      .get(`${url}/fundamental/assembly/${slug.stocks_slug}`)
      .then((response) => response.data);
  }
);
//#endregion

//#region Stock --> Realstate Report Action
// export const realstateReportAction = createAsyncThunk("fundamental/realstate", async (slug) => {
//   return axios.get(`${url}/fundamental/realstate/${slug.stocks_slug}`).then((response) => response.data);
// });
// //#endregion

export const realstateReportAction = createAsyncThunk(
  "fundamental/realstate",
  async (data) => {
    return axios
      .get(`${url}/fundamental/realstate/${data.slug.stocks_slug}`, {
        params: {
          year: data.year,
          season: data.season,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

export const InvTurnoverAction = createAsyncThunk(
  "fundamental/InvTurnover",
  async (data) => {
    return axios
      .get(`${url}/fundamental/InvTurnover/${data.slug.stocks_slug}`, {
        params: {
          period: data.period,
          season: data.season,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion

export const OtherIncomeAction = createAsyncThunk(
  "fundamental/OtherIncome",
  async (data) => {
    return axios
      .get(`${url}/fundamental/OtherIncome/${data.slug.stocks_slug}`, {
        params: {
          period: data.period,
          season: data.season,
        },
      })
      .then((response) => response.data);
  }
);
//#endregion
