import React from 'react';
import { Navbar, Nav, Container, Row, Col, Button, Card, Form, Carousel, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
  return (
    <div style={{direction:"ltr"}} className="home-page">


      <div className="hero-section">
        <Container >
          <Row className="align-items-center">
            <Col lg={6}>
            <h1 className="mb-4">DARA GPT</h1>

              <h1 className="mb-4">AI-Powered Commodity Price Prediction</h1>
              <p className="mb-4">Harness the power of advanced machine learning for accurate market forecasts</p>
          
              <Link
              to={`/Commodities/`}
              className="text-decoration-none w-100"
            >            
            
            
              <Button variant="primary" size="lg" className="me-3">Commodities</Button>
              </Link>

              {/* <Button variant="outline-light" size="lg">Learn More</Button> */}
            </Col>
            <Col lg={6}>
  
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt-5">
        <Row>
          <Col>
            <h2 style={{color:"white"}} className="text-center mb-5">Our Powerful Features</h2>
          </Col>
        </Row>
        <Row>
          {[
            { title: "Advanced Analytics", description: "Our AI algorithms process vast amounts of data to deliver accurate predictions.", icon: "fas fa-brain" },
            { title: "Real-time Updates", description: "Stay ahead with instant alerts and real-time market insights.", icon: "fas fa-bolt" },
            { title: "Global Coverage", description: "Access commodity data and predictions from markets worldwide.", icon: "fas fa-globe-americas" },
            { title: "Custom Alerts", description: "Set up personalized notifications for price movements and market events.", icon: "fas fa-bell" }
          ].map((feature, index) => (
            <Col md={6} lg={3} key={index}>
              <Card className="mb-4 feature-card">
                <Card.Body>
                  <i className={`${feature.icon} feature-icon`}></i>
                  <Card.Title>{feature.title}</Card.Title>
                  <Card.Text>{feature.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <div className="stats-section my-5 py-5">
        <Container>
          <Row>
            <Col md={3} className="text-center">
              <h3>92%</h3>
              <p>Prediction Accuracy</p>
            </Col>
            <Col md={3} className="text-center">
              <h3>1K+</h3>
              <p>Active Users</p>
            </Col>
            <Col md={3} className="text-center">
              <h3>10+</h3>
              <p>Commodities</p>
            </Col>
            <Col md={3} className="text-center">
              <h3>24/7</h3>
              <p>Support</p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="my-5">
        <Row>
          <Col>
            <h2 style={{color:'white'}} className="text-center mb-5">What Our Clients Say</h2>
          </Col>
        </Row>
        <Carousel>
          {[
            { name: "Mohsen Ali", role: "Commodity Trader", quote: "CommodityAI has revolutionized my trading strategy. The predictions are incredibly accurate!" },
            { name: "Jane Smith", role: "Financial Analyst", quote: "The real-time updates and global coverage have given me a significant edge in the market." },
            { name: "Mike Johnson", role: "Investment Manager", quote: "I've seen a substantial increase in my portfolio's performance since using CommodityAI." }
          ].map((testimonial, index) => (
            <Carousel.Item key={index}>
              <div className="testimonial-card">
                <p>"{testimonial.quote}"</p>
                <h5>{testimonial.name}</h5>
                <span>{testimonial.role}</span>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>

      <div className="cta-section my-5 py-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>

              <h2 className="mb-4">Ready to Predict the Future?</h2>
              <p className="mb-4">Join thousands of traders who trust our AI-powered predictions for their investment decisions.</p>
              {/* <Button variant="light" size="lg">Get Started Now</Button> */}
            </Col>
            <Col lg={6}>
              <Card className="pricing-card">
                <Card.Body>
                  <Card.Title className="text-center mb-4">Pro Plan</Card.Title>
                  <Card.Text className="price text-center">$99<span>/month</span></Card.Text>
                  <ul className="feature-list">
                    <li><i className="fas fa-check"></i> Unlimited predictions</li>
                    <li><i className="fas fa-check"></i> Real-time alerts</li>
                    <li><i className="fas fa-check"></i> Advanced analytics dashboard</li>
                    <li><i className="fas fa-check"></i> 24/7 support</li>
                  </ul>
                  {/* <Button variant="primary" block>Choose Plan</Button> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <footer className="bg-dark text-light py-4">
        <Container>
          <Row>
            <Col md={4}>
              <h5>CommodityAI</h5>
              <p>Empowering traders with AI-driven insights</p>
              <div className="social-icons">
                <i className="fab fa-facebook"></i>
                <i className="fab fa-twitter"></i>
                <i className="fab fa-linkedin"></i>
              </div>
            </Col>
            <Col md={4}>
              <h5>Quick Links</h5>
              <ul className="list-unstyled">
                <li><a href="/">Home</a></li>
                <li><a href="/#/Commodities">Commodities</a></li>
                <li><a href="/#/Commodities/brent">Oil</a></li>
                <li><a href="/#/Commodities/gold">Gold</a></li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Contact Us</h5>
              <p>Email: admin@daragpt.com</p>
              <p>Phone: +1 520-3605512</p>
              <p>Address: 4001 N Fontana Ave Tucson</p>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3">
              <p>&copy; 2024 CommodityAI. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>

      <style jsx>{`
        .home-page {
          font-family: 'Arial', sans-serif;
        }
        .hero-section {
          background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
          color: white;
          padding: 150px 0 100px;
          margin-bottom: 50px;
          clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        }
        .hero-image-wrapper {
          position: relative;
          height: 400px;
        }
        .hero-image {
          background: url('https://via.placeholder.com/500') no-repeat center center;
          background-size: cover;
          height: 100%;
          border-radius: 10px;
          box-shadow: 0 10px 20px rgba(0,0,0,0.1);
        }
        .floating-card {
          position: absolute;
          background: white;
          padding: 15px;
          border-radius: 8px;
          box-shadow: 0 5px 15px rgba(0,0,0,0.1);
          display: flex;
          align-items: center;
        }
        .floating-card i {
          font-size: 24px;
          margin-right: 10px;
          color: #667eea;
        }
        .card1 {
          top: 20%;
          left: -10%;
        }
        .card2 {
          bottom: 20%;
          right: -10%;
        }
        .feature-card {
          height: 100%;
          transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
          border: none;
          border-radius: 15px;
          overflow: hidden;
        }
        .feature-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 15px 30px rgba(0,0,0,0.1);
        }
        .feature-icon {
          font-size: 40px;
          color: #667eea;
          margin-bottom: 20px;
        }
        .stats-section {
          background-color: #f8f9fa;
        }
        .stats-section h3 {
          font-size: 40px;
          font-weight: bold;
          color: #667eea;
        }
        .testimonial-card {
          background-color: white;
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0 10px 20px rgba(0,0,0,0.05);
          text-align: center;
          margin: 0 auto;
          max-width: 600px;
        }
        .testimonial-card p {
          font-style: italic;
          font-size: 18px;
          margin-bottom: 20px;
        }
        .cta-section {
          background-color: #4a5568;
          color: white;
          clip-path: polygon(0 15%, 100% 0, 100% 85%, 0 100%);
        }
        .pricing-card {
          border: none;
          border-radius: 15px;
          overflow: hidden;
          box-shadow: 0 15px 30px rgba(0,0,0,0.1);
        }
        .pricing-card .price {
          font-size: 48px;
          font-weight: bold;
          color: #667eea;
        }
        .pricing-card .price span {
          font-size: 18px;
          font-weight: normal;
        }
        .feature-list {
          list-style-type: none;
          padding-left: 0;
        }
        .feature-list li {
          margin-bottom: 10px;
        }
        .feature-list i {
          color: #667eea;
          margin-right: 10px;
        }
        .social-icons i {
          font-size: 24px;
          margin-right: 15px;
          cursor: pointer;
        }
        footer a {
          color: white;
          text-decoration: none;
        }
        footer a:hover {
          color: #667eea;
        }
      `}</style>
    </div>
  );
}

export default Home;