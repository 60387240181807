import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../../config.json";
const url = `${configData.ApiAddress}/bourse/stock`;

//#region Stock --> ratioHistory
export const ratioHistoryAction = createAsyncThunk("rest/ratio_history", async (slug) => {
  return axios.get(`${url}/rest/ratio_history/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

//#region Stock --> finalPriceRecord
export const finalPriceRecordAction = createAsyncThunk("rest/finalpricerecord", async (slug) => {
  return axios.get(`${url}/rest/finalpricerecord/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion













//#region Stock --> pe_psChart
export const psChartAction = createAsyncThunk("rest/PS", async (slug) => {
  return axios.get(`${url}/rest/PS/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion



//#region Stock --> pe_psChart
export const peChartAction = createAsyncThunk("rest/PE", async (slug) => {
  return axios.get(`${url}/rest/PE/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion


















//#region Stock --> relativeReturns
export const relativeReturnsAction = createAsyncThunk("rest/relativeReturns", async (slug) => {
  return axios.get(`${url}/rest/relativeReturns/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

//#region Stock --> budget
export const budgetAction = createAsyncThunk("rest/budget", async (data) => {
  return axios
    .get(`${url}/rest/budget/${data.stocks_slug}`, {
      params: {
        year: data.year,
      },
    })
    .then((response) => response.data);
});
//#endregion

//#region Stock --> ime
export const imeAction = createAsyncThunk("rest/ime", async (data) => {
  return axios
    .get(`${url}/rest/ime/${data.slug.stocks_slug}`, {
      params: {
        year: data.year,
      },
    })
    .then((response) => response.data);
});
//#endregion

//#region Stock --> getComments
export const getCommentsAction = createAsyncThunk("rest/getComments", async (slug) => {
  return axios.get(`${url}/rest/comments/${slug.stocks_slug}`).then((response) => response.data);
});
//#endregion

//#region Stock --> Seasonal Report
export const getSingleCommentAction = createAsyncThunk("rest/get_single_Comments", async (data) => {
  return axios

    .get(`${url}/rest/get_single_Comments/${data.id}`, {})

    .then((response) => response.data);
});
//#endregion

//#region Stock --> postComment
export const postCommentAction = createAsyncThunk("rest/postComment", async (data) => {
  return axios.post(`${url}/rest/postcomment/${data.slug}`, data).then((response) => response.data);
});
//#endregion

//#region Stock --> getCorrelation
export const getCorrelationAction = createAsyncThunk("rest/getCorrelation", async (result) => {
  return axios
    .get(`${url}/rest/Correlation/`, {
      params: { data: result },
    })
    .then((response) => response.data);
});
//#endregion
