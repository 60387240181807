import { createSlice } from "@reduxjs/toolkit";
import {
  getUserAnalysisAction,
  createUserAnalysisAction,
  deleteUserAnalysisAction,
  userAnalysisDetailsAction,
  budgetDetailAnalysisAction,
} from "./bourseStockTahlilAction";

//#region userAnalysis
export const userAnalysisSlice = createSlice({
  name: "userAnalysisData",
  initialState: {
    userAnalysisLoading: false,
    userAnalysis: [],
    userAnalysisError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch userAnalysisAction
    builder.addCase(getUserAnalysisAction.pending, (state, action) => {
      state.userAnalysisLoading = true;
    });
    builder.addCase(getUserAnalysisAction.fulfilled, (state, action) => {
      state.userAnalysisLoading = false;
      state.userAnalysis = action.payload;
      state.userAnalysisError = null;
    });
    builder.addCase(getUserAnalysisAction.rejected, (state, action) => {
      state.userAnalysisLoading = false;
      state.userAnalysisError = action.error.message;
    });

    //  create userAnalysisAction
    builder.addCase(createUserAnalysisAction.pending, (state, action) => {
      state.userAnalysisLoading = true;
    });
    builder.addCase(createUserAnalysisAction.fulfilled, (state, action) => {
      state.userAnalysisLoading = false;
      // state.userAnalysis = action.payload;
      state.userAnalysisError = null;
    });
    builder.addCase(createUserAnalysisAction.rejected, (state, action) => {
      state.userAnalysisLoading = false;
      state.userAnalysisError = action.error.message;
    });

    // delete userAnalysisAction
    builder.addCase(deleteUserAnalysisAction.pending, (state, action) => {
      state.userAnalysisLoading = true;
    });
    builder.addCase(deleteUserAnalysisAction.fulfilled, (state, action) => {
      state.userAnalysisLoading = false;
      // state.userAnalysis = action.payload;
      state.userAnalysisError = null;
    });
    builder.addCase(deleteUserAnalysisAction.rejected, (state, action) => {
      state.userAnalysisLoading = false;
      state.userAnalysisError = action.error.message;
    });
  },
});
//#endregion

//#region userAnalysisDetails
export const userAnalysisDetailsSlice = createSlice({
  name: "userAnalysisDetailsData",
  initialState: {
    userAnalysisDetailsLoading: false,
    userAnalysisDetails: [],
    userAnalysisDetailsError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch userAnalysisDetailsAction
    builder.addCase(userAnalysisDetailsAction.pending, (state, action) => {
      state.userAnalysisDetailsLoading = true;
    });
    builder.addCase(userAnalysisDetailsAction.fulfilled, (state, action) => {
      state.userAnalysisDetailsLoading = false;
      state.userAnalysisDetails = action.payload;
      state.userAnalysisDetailsError = null;
    });
    builder.addCase(userAnalysisDetailsAction.rejected, (state, action) => {
      state.userAnalysisDetailsLoading = false;
      state.userAnalysisDetailsError = action.error.message;
    });
  },
});
//#endregion

//#region budgetDetailAnalysis
export const budgetDetailAnalysisSlice = createSlice({
  name: "budgetDetailAnalysisData",
  initialState: {
    budgetDetailAnalysisLoading: false,
    budgetDetailAnalysis: [],
    budgetDetailAnalysisError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch budgetDetailAnalysisAction
    builder.addCase(budgetDetailAnalysisAction.pending, (state, action) => {
      state.budgetDetailAnalysisLoading = true;
    });
    builder.addCase(budgetDetailAnalysisAction.fulfilled, (state, action) => {
      state.budgetDetailAnalysisLoading = false;
      state.budgetDetailAnalysis = action.payload;
      state.budgetDetailAnalysisError = null;
    });
    builder.addCase(budgetDetailAnalysisAction.rejected, (state, action) => {
      state.budgetDetailAnalysisLoading = false;
      state.budgetDetailAnalysisError = action.error.message;
    });
  },
});
//#endregion
