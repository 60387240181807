import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import configData from "../config.json";
const url = `${configData.ApiAddress}/bourse`;
const url_copilot = `${configData.ApiAddress}/copilot`;

//#region publications
export const publicationsAction = createAsyncThunk(
  "main/publications",
  async () => {
    return axios
      .get(`${url}/main/publications/`)
      .then((response) => response.data);
  }
);
//#endregion

//#region publication
export const publicationAction = createAsyncThunk(
  "main/publication",
  async (slug) => {
    return axios
      .get(`${url}/main/publications/${slug.publications_slugs}/`)
      .then((response) => response.data);
  }
);
//#endregion

//#region publication
export const sendChatbotMessageAction = createAsyncThunk(
  "chatbot/sendMessage",
  async (message) => {
    return axios
      .get(`${url_copilot}/main/chat?message="${encodeURIComponent(message)}"`)
      .then((response) => response.data);
  }
);
//#endregion
