import { createSlice } from "@reduxjs/toolkit";
import axiosHeaderConfig from "../../config";

import {
  userInfoAction,
  loginAction,
  registerAction,
  userProfileAction,
  updateUserProfileAction,
} from "./userAccountAction";

export const userInfoSlice = createSlice({
  name: "userInfoData",
  initialState: {
    loading: false,
    // info: null,
    info: localStorage.getItem("userInfoData")
      ? JSON.parse(localStorage.getItem("userInfoData"))
      : null,
    details: { user: {} },
    error: "",
    updateSuccessfully: false,
    // serverMessage: "",
  },
  reducers: {
    clearHandleError: (state, action) => {
      // console.log("temp");
      // state.handleError = "";
    },
    logout: (state, action) => {
      localStorage.removeItem("userInfoData");
      axiosHeaderConfig(null);
      state.info = null;
    },
  },
  extraReducers: (builder) => {
    // fetch userInfoAction
    // if (info) axiosHeaderConfig(info.token);

    //login
    builder.addCase(loginAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginAction.fulfilled, (state, action) => {
      localStorage.setItem("userInfoData", JSON.stringify(action.payload));
      axiosHeaderConfig(action.payload.token);
      state.loading = false;
      state.info = action.payload;
      state.error = "";
    });
    builder.addCase(loginAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //register
    builder.addCase(registerAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(registerAction.fulfilled, (state, action) => {
      localStorage.setItem("userInfoData", JSON.stringify(action.payload));
      axiosHeaderConfig(action.payload.token);
      state.loading = false;
      state.info = action.payload;
      state.error = "";
    });
    builder.addCase(registerAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //userProfile
    builder.addCase(userProfileAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userProfileAction.fulfilled, (state, action) => {
      state.loading = false;
      console.log("action.payload");
      console.log(action.payload);
      state.details = action.payload;
      state.error = "";
    });
    builder.addCase(userProfileAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //update Profile
    builder.addCase(updateUserProfileAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateUserProfileAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateSuccessfully = true;
      state.error = "";
    });
    builder.addCase(updateUserProfileAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export const { logout } = userInfoSlice.actions;
// export default slice.reducer;
