import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { userInfoSlice } from "../redux/usersRedux/userAccountRedux/userAccountReducer";
// import { userInfoSlice } from "../redux/userInfoRedux/UserInfoReducer";

import {
  publicationsSlice,
  publicationSlice,
  chatbotSlice,
} from "../redux/mainPagesRedux/mainPageReducer";

import {
  MarketTrackingSlice,
  dailyIndexSlice,
  smartMoneySlice,
  stocksGroupsSlice,
  FundsGroupsSlice,
  fundsListSlice,
  allStocksSlice,
  allFundSlice,
  stocksListSlice,
  commoditiesSlice,
  marketMapSlice,
  AddToCartSlice,
  CartSlice,
  DeleteFromCartSlice,
  FilterSlice,
  MonthReportSlice,
  MonthReport1Slice,
  singlecommoSlice,
  UserThemeSlice,
  StockHolderFilterSlice,
  DaramadSabetTrackingSlice,
  boursemainimeSlice,
  line_trackingSlice,
  MonthlyFilterSlice,
  AssemblyFilterSlice,
  MarginFilterSlice,
  PriceActionFilterSlice,
  ChartPatternsFilterSlice,
  PEPSFilterSlice,
  ImeTodaySlice,
  ImeTalarSlice,
  ImeCommoditySlice,
  OtherImeCommoditySlice,
  ComparisonSlice,
  MonthlyComparisonnamesSlice,
  MonthlyComparisonSlice,
  GoldGroupSlice,
  indextrackingSlice,
  BubbleMapSlice,
  GoldFundSlice,
  
  ArzSlice,
  goldrestSlice,
  GoldCurrencyCommodityParagraphSlice,
  HaghSlice,
  goldcoinbourseSlice,
  goldcoinazadSlice,
  GoldCurrencySlice,
  MarketMSlice,
  MarketMFSlice,
  ComingSoonPageSlice,
} from "../redux/bourseMainRedux/bourseMainReducer";

import {
  stockboxSlice,
  tseboxSlice,
  orderTableSlice,
  CompetitorsTableSlice,
  ratioTableSlice,
  stockHolderSlice,
  dailyPriceChartSlice,
  ratioTableDailyTrackingSlice,
  sidebarSlice,
  sliderSlice,
} from "../redux/bourseStockRedux/mainPage/bourseStockMainPageReducer";

import {
  monthlyReportSlice,
  seasonalReportSlice,
  lastSeasonalReportSlice,
  balanceReportSlice,
  marginReportSlice,
  assemblyReportSlice,
  realstateReportSlice,
  InvTurnoverSlice,
  OtherIncomeSlice,
  CommodityMavadAvalieSlice,
} from "../redux/bourseStockRedux/fundamental/bourseStockFundamentalReducer";

import {
  userAnalysisSlice,
  userAnalysisDetailsSlice,
  budgetDetailAnalysisSlice,
} from "../redux/bourseStockRedux/tahlil/bourseStockTahlilReducer";

import {
  ratioHistorySlice,
  finalPriceRecordSlice,
  psChartSlice,
  peChartSlice,
  relativeReturnsSlice,
  budgetSlice,
  correlationSlice,
  imeSlice,
  commentsSlice,
  getSingleCommentSlice,
} from "../redux/bourseStockRedux/rest/bourseStockRestReducer";

import {
  UserWatchlistSlice,
  UserAddWatchlistSlice,
  UserSingleWatchlistSlice,
  UserAddstockWatchlistSlice,
  UserDeletestockWatchlistSlice,
  UserDeleteWatchlistSlice,
  UserAlarmsSlice,
  UserAddAlarmsSlice,
  UserDeleteAlarmsSlice,
  UserAddCommentSlice,
  UserAddLikeSlice,
  UserAdddisLikeSlice,
  UserReportCommentSlice,
} from "../redux/usersRedux/userFinancialRedux/userFinancialReducer";

import {
  AdminUserSlice,
  AdminChangeUserSubscriptionSlice,
  AdminURLSSlice,
  AdminMonthlySlice,
  AdminBudgetSlice,
  AdminMonthlySingleSlice,
  AdminMonthlyDeleteSlice,
  AdminSeasonSlice,
  AdminBalanceSlice,
  AdminRealStateSlice,
  AdminAssemblySlice,
  AdminAddStockSlice,
  AdminAssemblyAddSlice,
  AdmindeleteStockSlice,
  AdminupdateStockSlice,
  AdminmainSlice,
  AdminSeasonUpdateSlice,
  AdminSeasonDeleteYearSlice,
  AdminSeasonDeleteSubjectSlice,
  AdminSeasonAddSlice,
  AdminSeasonSingleSlice,
  AdminGoldDollarSlice,
  AdminTecnicalChartSlice,
  AdminSeasonDetailSlice,
  AdminSeasonDetailCommoditySlice,
  AdminSeasonTypeSlice,
} from "../redux/adminRedux/AdminReducer";

import {
  PriceActionSlice,
  OHLCSlice,
  IndicatorSlice,
  ChartPatternSlice,
  ConvergenceDivergenceSlice,
} from "../redux/bourseStockRedux/ai/bourseStockAiReducer";

import {
  FundBoxSlice,
  FundTseBoxSlice,
  FundCompetitorsTableSlice,
  FundChartSlice,
  fundratioTableDailyTrackingSlice,
  fundorderTableSlice,
  fundsliderSlice,
  fundHolderSlice,
  fundratioTableSlice,
} from "../redux/bourseFundRedux/mainpage/bourseFundMainPageReducer";

import {
  NAVHistorySlice,
  fundfinalPriceRecordSlice,
  fundratioHistorySlice,
} from "../redux/bourseFundRedux/rest/bourseFundRestReducer";

const reducers = combineReducers({
  [userInfoSlice.name]: userInfoSlice.reducer,

  [publicationsSlice.name]: publicationsSlice.reducer,
  [publicationSlice.name]: publicationSlice.reducer,

  [chatbotSlice.name]: chatbotSlice.reducer,

  [MarketTrackingSlice.name]: MarketTrackingSlice.reducer,
  [dailyIndexSlice.name]: dailyIndexSlice.reducer,
  [smartMoneySlice.name]: smartMoneySlice.reducer,
  [stocksGroupsSlice.name]: stocksGroupsSlice.reducer,

  [FundsGroupsSlice.name]: FundsGroupsSlice.reducer,
  [fundsListSlice.name]: fundsListSlice.reducer,

  [allStocksSlice.name]: allStocksSlice.reducer,
  [allFundSlice.name]: allFundSlice.reducer,

  [stocksListSlice.name]: stocksListSlice.reducer,
  [commoditiesSlice.name]: commoditiesSlice.reducer,
  [marketMapSlice.name]: marketMapSlice.reducer,
  [FilterSlice.name]: FilterSlice.reducer,
  [StockHolderFilterSlice.name]: StockHolderFilterSlice.reducer,
  [DaramadSabetTrackingSlice.name]: DaramadSabetTrackingSlice.reducer,
  [boursemainimeSlice.name]: boursemainimeSlice.reducer,
  [line_trackingSlice.name]: line_trackingSlice.reducer,

  [AssemblyFilterSlice.name]: AssemblyFilterSlice.reducer,
  [MonthlyFilterSlice.name]: MonthlyFilterSlice.reducer,
  [MarginFilterSlice.name]: MarginFilterSlice.reducer,
  [PriceActionFilterSlice.name]: PriceActionFilterSlice.reducer,
  [ChartPatternsFilterSlice.name]: ChartPatternsFilterSlice.reducer,
  [MonthReportSlice.name]: MonthReportSlice.reducer,
  [MonthReport1Slice.name]: MonthReport1Slice.reducer,
  [singlecommoSlice.name]: singlecommoSlice.reducer,

  [UserThemeSlice.name]: UserThemeSlice.reducer,

  [PEPSFilterSlice.name]: PEPSFilterSlice.reducer,

  [goldcoinbourseSlice.name]: goldcoinbourseSlice.reducer,
  [goldcoinazadSlice.name]: goldcoinazadSlice.reducer,
  [GoldCurrencySlice.name]: GoldCurrencySlice.reducer,

  [MarketMSlice.name]: MarketMSlice.reducer,
  [MarketMFSlice.name]: MarketMFSlice.reducer,


  
  
  [ArzSlice.name]: ArzSlice.reducer,
  [HaghSlice.name]: HaghSlice.reducer,
  
  [goldrestSlice.name]: goldrestSlice.reducer,

  [GoldCurrencyCommodityParagraphSlice.name]: GoldCurrencyCommodityParagraphSlice.reducer,

  
  
  [GoldFundSlice.name]: GoldFundSlice.reducer,
  [GoldGroupSlice.name]: GoldGroupSlice.reducer,
  [indextrackingSlice.name]: indextrackingSlice.reducer,
  [BubbleMapSlice.name]: BubbleMapSlice.reducer,

  [ImeTodaySlice.name]: ImeTodaySlice.reducer,
  [ImeTalarSlice.name]: ImeTalarSlice.reducer,
  [ImeCommoditySlice.name]: ImeCommoditySlice.reducer,
  [OtherImeCommoditySlice.name]: OtherImeCommoditySlice.reducer,

  [ComparisonSlice.name]: ComparisonSlice.reducer,
  [MonthlyComparisonnamesSlice.name]: MonthlyComparisonnamesSlice.reducer,
  [MonthlyComparisonSlice.name]: MonthlyComparisonSlice.reducer,

  [CartSlice.name]: CartSlice.reducer,
  [AddToCartSlice.name]: AddToCartSlice.reducer,
  [DeleteFromCartSlice.name]: DeleteFromCartSlice.reducer,

  [stockboxSlice.name]: stockboxSlice.reducer,
  [tseboxSlice.name]: tseboxSlice.reducer,
  [orderTableSlice.name]: orderTableSlice.reducer,
  [CompetitorsTableSlice.name]: CompetitorsTableSlice.reducer,
  [ratioTableSlice.name]: ratioTableSlice.reducer,
  [stockHolderSlice.name]: stockHolderSlice.reducer,
  [dailyPriceChartSlice.name]: dailyPriceChartSlice.reducer,
  [ratioTableDailyTrackingSlice.name]: ratioTableDailyTrackingSlice.reducer,
  [sidebarSlice.name]: sidebarSlice.reducer,
  [sliderSlice.name]: sliderSlice.reducer,

  [monthlyReportSlice.name]: monthlyReportSlice.reducer,
  [seasonalReportSlice.name]: seasonalReportSlice.reducer,
  [lastSeasonalReportSlice.name]: lastSeasonalReportSlice.reducer,
  [balanceReportSlice.name]: balanceReportSlice.reducer,
  [marginReportSlice.name]: marginReportSlice.reducer,
  [assemblyReportSlice.name]: assemblyReportSlice.reducer,
  [realstateReportSlice.name]: realstateReportSlice.reducer,
  [InvTurnoverSlice.name]: InvTurnoverSlice.reducer,
  [CommodityMavadAvalieSlice.name]: CommodityMavadAvalieSlice.reducer,

  [userAnalysisSlice.name]: userAnalysisSlice.reducer,
  [userAnalysisDetailsSlice.name]: userAnalysisDetailsSlice.reducer,
  [budgetDetailAnalysisSlice.name]: budgetDetailAnalysisSlice.reducer,

  [ratioHistorySlice.name]: ratioHistorySlice.reducer,
  [finalPriceRecordSlice.name]: finalPriceRecordSlice.reducer,
  [psChartSlice.name]: psChartSlice.reducer,

  [peChartSlice.name]: peChartSlice.reducer,

  [relativeReturnsSlice.name]: relativeReturnsSlice.reducer,
  [budgetSlice.name]: budgetSlice.reducer,
  [correlationSlice.name]: correlationSlice.reducer,
  [imeSlice.name]: imeSlice.reducer,

  [commentsSlice.name]: commentsSlice.reducer,
  [getSingleCommentSlice.name]: getSingleCommentSlice.reducer,

  [UserWatchlistSlice.name]: UserWatchlistSlice.reducer,
  [UserAddWatchlistSlice.name]: UserAddWatchlistSlice.reducer,
  [UserSingleWatchlistSlice.name]: UserSingleWatchlistSlice.reducer,
  [UserAddstockWatchlistSlice.name]: UserAddstockWatchlistSlice.reducer,
  [UserDeletestockWatchlistSlice.name]: UserDeletestockWatchlistSlice.reducer,
  [UserDeleteWatchlistSlice.name]: UserDeleteWatchlistSlice.reducer,

  [UserAlarmsSlice.name]: UserAlarmsSlice.reducer,
  [UserAddAlarmsSlice.name]: UserAddAlarmsSlice.reducer,
  [UserDeleteAlarmsSlice.name]: UserDeleteAlarmsSlice.reducer,

  [UserAddCommentSlice.name]: UserAddCommentSlice.reducer,
  [UserAddLikeSlice.name]: UserAddLikeSlice.reducer,
  [UserAdddisLikeSlice.name]: UserAdddisLikeSlice.reducer,

  [UserReportCommentSlice.name]: UserReportCommentSlice.reducer,

  [AdminAddStockSlice.name]: AdminAddStockSlice.reducer,

  [AdminAssemblyAddSlice.name]: AdminAssemblyAddSlice.reducer,

  [AdminUserSlice.name]: AdminUserSlice.reducer,
  [AdminChangeUserSubscriptionSlice.name]:
    AdminChangeUserSubscriptionSlice.reducer,
  [AdminURLSSlice.name]: AdminURLSSlice.reducer,
  [AdminMonthlySlice.name]: AdminMonthlySlice.reducer,
  [AdminBudgetSlice.name]: AdminBudgetSlice.reducer,
  [AdminMonthlySingleSlice.name]: AdminMonthlySingleSlice.reducer,
  [AdminMonthlyDeleteSlice.name]: AdminMonthlyDeleteSlice.reducer,
  [AdminSeasonSlice.name]: AdminSeasonSlice.reducer,
  [AdminSeasonDetailSlice.name]: AdminSeasonDetailSlice.reducer,
  [AdminSeasonDetailCommoditySlice.name]:
    AdminSeasonDetailCommoditySlice.reducer,
  [AdminSeasonTypeSlice.name]: AdminSeasonTypeSlice.reducer,
  [AdminBalanceSlice.name]: AdminBalanceSlice.reducer,
  [AdminRealStateSlice.name]: AdminRealStateSlice.reducer,
  [AdminAssemblySlice.name]: AdminAssemblySlice.reducer,
  [AdmindeleteStockSlice.name]: AdmindeleteStockSlice.reducer,
  [AdminupdateStockSlice.name]: AdminupdateStockSlice.reducer,

  [AdminmainSlice.name]: AdminmainSlice.reducer,
  [AdminSeasonUpdateSlice.name]: AdminSeasonUpdateSlice.reducer,
  [AdminSeasonDeleteYearSlice.name]: AdminSeasonDeleteYearSlice.reducer,
  [AdminSeasonDeleteSubjectSlice.name]: AdminSeasonDeleteSubjectSlice.reducer,
  [AdminSeasonAddSlice.name]: AdminSeasonAddSlice.reducer,
  [AdminSeasonSingleSlice.name]: AdminSeasonSingleSlice.reducer,

  [AdminGoldDollarSlice.name]: AdminGoldDollarSlice.reducer,
  [AdminTecnicalChartSlice.name]: AdminTecnicalChartSlice.reducer,

  [PriceActionSlice.name]: PriceActionSlice.reducer,
  [OHLCSlice.name]: OHLCSlice.reducer,
  [ChartPatternSlice.name]: ChartPatternSlice.reducer,
  [ConvergenceDivergenceSlice.name]: ConvergenceDivergenceSlice.reducer,
  [IndicatorSlice.name]: IndicatorSlice.reducer,

  [FundBoxSlice.name]: FundBoxSlice.reducer,
  [FundTseBoxSlice.name]: FundTseBoxSlice.reducer,
  [FundCompetitorsTableSlice.name]: FundCompetitorsTableSlice.reducer,
  [FundChartSlice.name]: FundChartSlice.reducer,

  [fundratioTableDailyTrackingSlice.name]:
    fundratioTableDailyTrackingSlice.reducer,
  [fundorderTableSlice.name]: fundorderTableSlice.reducer,
  [fundsliderSlice.name]: fundsliderSlice.reducer,
  [fundHolderSlice.name]: fundHolderSlice.reducer,
  [fundratioTableSlice.name]: fundratioTableSlice.reducer,
  [OtherIncomeSlice.name]: OtherIncomeSlice.reducer,

  [ComingSoonPageSlice.name]: ComingSoonPageSlice.reducer,

  [NAVHistorySlice.name]: NAVHistorySlice.reducer,

  [fundfinalPriceRecordSlice.name]: fundfinalPriceRecordSlice.reducer,
  [fundratioHistorySlice.name]: fundratioHistorySlice.reducer,
});

const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;























