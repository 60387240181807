import axios from "axios";
// export const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${userInfo.token}`,
//     },
//   };

function axiosHeaderConfig(token) {
  if (token) {
    // console.log("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // console.log("Notoken");
    axios.defaults.headers.common["Authorization"] = null;
    /*if setting null does not remove `Authorization` header then try     
           delete axios.defaults.headers.common['Authorization'];
         */
  }
}

export default axiosHeaderConfig;
