import { createSlice } from "@reduxjs/toolkit";
import {
  ratioHistoryAction,
  finalPriceRecordAction,
  psChartAction,
  peChartAction,
  relativeReturnsAction,
  budgetAction,
  getCorrelationAction,
  getCommentsAction,
  getSingleCommentAction,
  imeAction,
} from "./bourseStockRestAction";

//#region ratioHistory
export const ratioHistorySlice = createSlice({
  name: "ratioHistoryData",
  initialState: {
    ratioHistoryLoading: false,
    ratioHistory: [],
    ratioHistoryError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch ratioHistoryAction
    builder.addCase(ratioHistoryAction.pending, (state, action) => {
      state.ratioHistoryLoading = true;
    });
    builder.addCase(ratioHistoryAction.fulfilled, (state, action) => {
      state.ratioHistoryLoading = false;
      state.ratioHistory = action.payload;
      state.ratioHistoryError = null;
    });
    builder.addCase(ratioHistoryAction.rejected, (state, action) => {
      state.ratioHistoryLoading = false;
      state.ratioHistoryError = action.error.message;
    });
  },
});
//#endregion

//#region finalPriceRecord
export const finalPriceRecordSlice = createSlice({
  name: "finalPriceRecordData",
  initialState: {
    finalPriceRecordLoading: false,
    finalPriceRecord: [],
    finalPriceRecordError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch finalPriceRecordAction
    builder.addCase(finalPriceRecordAction.pending, (state, action) => {
      state.finalPriceRecordLoading = true;
    });
    builder.addCase(finalPriceRecordAction.fulfilled, (state, action) => {
      state.finalPriceRecordLoading = false;
      state.finalPriceRecord = action.payload;
      state.finalPriceRecordError = null;
    });
    builder.addCase(finalPriceRecordAction.rejected, (state, action) => {
      state.finalPriceRecordLoading = false;
      state.finalPriceRecordError = action.error.message;
    });
  },
});
//#endregion

//#region pe_psChart
export const psChartSlice = createSlice({
  name: "psChartData",
  initialState: {
    psChartLoading: false,
    psChart: [],
    psChartError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch psChartAction
    builder.addCase(psChartAction.pending, (state, action) => {
      state.psChartLoading = true;
    });
    builder.addCase(psChartAction.fulfilled, (state, action) => {
      state.psChartLoading = false;
      state.psChart = action.payload;
      state.psChartError = null;
    });
    builder.addCase(psChartAction.rejected, (state, action) => {
      state.psChartLoading = false;
      state.psChartError = action.error.message;
    });
  },
});
//#endregion










//#region pe_psChart
export const peChartSlice = createSlice({
  name: "peChartData",
  initialState: {
    peChartLoading: false,
    peChart: [],
    peChartError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch peChartAction
    builder.addCase(peChartAction.pending, (state, action) => {
      state.peChartLoading = true;
    });
    builder.addCase(peChartAction.fulfilled, (state, action) => {
      state.peChartLoading = false;
      state.peChart = action.payload;
      state.peChartError = null;
    });
    builder.addCase(peChartAction.rejected, (state, action) => {
      state.peChartLoading = false;
      state.peChartError = action.error.message;
    });
  },
});
//#endregion















//#region relativeReturns
export const relativeReturnsSlice = createSlice({
  name: "relativeReturnsData",
  initialState: {
    relativeReturnsLoading: false,
    relativeReturns: [],
    relativeReturnsError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch relativeReturnsAction
    builder.addCase(relativeReturnsAction.pending, (state, action) => {
      state.relativeReturnsLoading = true;
    });
    builder.addCase(relativeReturnsAction.fulfilled, (state, action) => {
      state.relativeReturnsLoading = false;
      state.relativeReturns = action.payload;
      state.relativeReturnsError = null;
    });
    builder.addCase(relativeReturnsAction.rejected, (state, action) => {
      state.relativeReturnsLoading = false;
      state.relativeReturnsError = action.error.message;
    });
  },
});
//#endregion

//#region budget
export const budgetSlice = createSlice({
  name: "budgetData",
  initialState: {
    budgetLoading: false,
    budget: [],
    budgetError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch budgetAction
    builder.addCase(budgetAction.pending, (state, action) => {
      state.budgetLoading = true;
    });
    builder.addCase(budgetAction.fulfilled, (state, action) => {
      state.budgetLoading = false;
      state.budget = action.payload;
      state.budgetError = null;
    });
    builder.addCase(budgetAction.rejected, (state, action) => {
      state.budgetLoading = false;
      state.budgetError = action.error.message;
    });
  },
});
//#endregion

//#region Correlation
export const correlationSlice = createSlice({
  name: "correlationData",
  initialState: {
    correlationLoading: false,
    correlation: [],
    correlationError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch correlationAction
    builder.addCase(getCorrelationAction.pending, (state, action) => {
      state.correlationLoading = true;
    });
    builder.addCase(getCorrelationAction.fulfilled, (state, action) => {
      state.correlationLoading = false;
      state.correlation = action.payload;
      state.correlationError = null;
    });
    builder.addCase(getCorrelationAction.rejected, (state, action) => {
      state.correlationLoading = false;
      state.correlationError = action.error.message;
    });
  },
});
//#endregion

//#region ime
export const imeSlice = createSlice({
  name: "imeData",
  initialState: {
    imeLoading: false,
    ime: [],
    imeError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch imeAction
    builder.addCase(imeAction.pending, (state, action) => {
      state.imeLoading = true;
    });
    builder.addCase(imeAction.fulfilled, (state, action) => {
      state.imeLoading = false;
      state.ime = action.payload;
      state.imeError = null;
    });
    builder.addCase(imeAction.rejected, (state, action) => {
      state.imeLoading = false;
      state.imeError = action.error.message;
    });
  },
});
//#endregion

//#region comments
export const commentsSlice = createSlice({
  name: "commentsData",
  initialState: {
    commentsLoading: false,
    comments: [],
    commentsError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch commentsAction
    builder.addCase(getCommentsAction.pending, (state, action) => {
      state.commentsLoading = true;
    });
    builder.addCase(getCommentsAction.fulfilled, (state, action) => {
      state.commentsLoading = false;
      state.comments = action.payload;
      state.commentsError = null;
    });
    builder.addCase(getCommentsAction.rejected, (state, action) => {
      state.commentsLoading = false;
      state.commentsError = action.error.message;
    });
  },
});
//#endregion







//#region ime
export const getSingleCommentSlice = createSlice({
  name: "getSingleCommentData",
  initialState: {
    getSingleCommentLoading: false,
    getSingleComment: [],
    getSingleCommentError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch getSingleCommentAction
    builder.addCase(getSingleCommentAction.pending, (state, action) => {
      state.getSingleCommentLoading = true;
    });
    builder.addCase(getSingleCommentAction.fulfilled, (state, action) => {
      state.getSingleCommentLoading = false;
      state.getSingleComment = action.payload;
      state.getSingleCommentError = null;
    });
    builder.addCase(getSingleCommentAction.rejected, (state, action) => {
      state.getSingleCommentLoading = false;
      state.getSingleCommentError = action.error.message;
    });
  },
});
//#endregion