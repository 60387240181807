import { React, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

import { singlecommoAction } from "../../redux/bourseMainRedux/bourseMainAction";
import Error from "../../components/common/Error";
import Loader from "../../components/common/Loader";

function Bollinger_Bands({ color }) {
  const table = color === "black" ? "modern-table" : "modern-table";

  const color1 =
    color === "white" ? "rgba(255, 158, 68, 0.5)" : "rgba(255, 158, 68, 0.5)";
  const color2 =
    color === "white" ? "rgba(255, 70, 131, 0.1)" : "rgba(255, 70, 131, 0.1)";

  const itemStyleColor = color === "white" ? "#ff4683" : "#ff4683";

  if (color == "black") {
    var className = "blackbackgroundImage";
  } else if (color == "white") {
    var className = "blackbackgroundImage";
  }

  var slug = useParams();
  const dispatch = useDispatch();

  const singlecommoData = useSelector((state) => state.singlecommoData);
  const { singlecommoError, singlecommoLoading, singlecommo } = singlecommoData;

  var date = [];
  var data = [];

  singlecommo.forEach((element) => {
    date.push(element.date);
    data.push(element.close);
  });

  const chartOption = useMemo(
    () => ({
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      title: {
        left: "center",
        text: slug.Commodities_slug,
        textStyle: {
          fontWeight: "bold",
          fontSize: 16,
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: date,
        boundaryGap: false,
        axisLabel: {
          color:"white",
          fontSize: 12,
          fontFamily: "IranSans",
          rotate: 45,
        },
        axisLine: {
          lineStyle: {
            color:"white",
          },
        },
      },
      yAxis: {
        type: "value",
        nameTextStyle: {   color:"white", fontSize: 12 },
        axisLine: { show: false },
        axisLabel: {   color:"white", fontSize: 12 },
        splitLine: {
          show: true,
          lineStyle: { color: "#e9ecef", type: "dashed" },
        },
      },
      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100,
        },
        {
          start: 0,
          end: 100,
          handleSize: "80%",
          handleStyle: {
            color: "#fff",
            shadowBlur: 3,
            shadowColor: "rgba(0, 0, 0, 0.6)",
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
        },
      ],
      series: [
        {
          name: data,
          type: "line",
          smooth: true,
          symbol: "circle",
          symbolSize: 8,
          sampling: "lttb",
          animation: true,
          animationDelay: (idx) => idx * 10,
          markLine: {
            data: [
              {
                type: "average",
                name: "Avg",
                label: {
                  show: true,
                  position: "start",
                  color: color,
                  fontSize: 12,
                  fontWeight: "bold",
                },
                lineStyle: {
                  color: "white",
                  type: "dashed",
                },
              },
            ],
          },
          itemStyle: {
            color: itemStyleColor,
            borderColor: "#fff",
            borderWidth: 2,
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: color1,
              },
              {
                offset: 1,
                color: color2,
              },
            ]),
          },
          data: data,
        },
      ],
    }),
    [slug.Commodities_slug, color, date, itemStyleColor, color1, color2, data]
  );

  useEffect(() => {
    dispatch(singlecommoAction(slug));
  }, [dispatch]);

  let user_height = window.innerHeight;

  return (
    <Container
      className={className}
      style={{ minHeight: 0.94 * user_height }}
      fluid
    >
      <Row className="pt-3">





      </Row>

      <Row className="py-4">
        <Col md={12}>
          <Card
            style={{
              backgroundColor: "transparent",
              border: "1px solid #193266",
            }}
          >
            <Card.Body>
              {singlecommoLoading ? (
                <Loader />
              ) : singlecommoError ? (
                <Error variant="danger"> {singlecommoError}</Error>
              ) : (
                <ReactECharts option={chartOption} style={{ height: "60vh" }} />
              )}
            </Card.Body>
          </Card>
        </Col>





















      </Row>
    </Container>
  );
}

export default Bollinger_Bands;


