import Spinner from "react-bootstrap/Spinner";

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "15vh",
      }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export default Loader;
