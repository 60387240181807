import { createSlice } from "@reduxjs/toolkit";

import {
  UserWatchlistAction,
  UserAddWatchlistAction,
  UserSingleWatchlistAction,
  UserAddstockWatchlistAction,UserDeletestockWatchlistAction,UserDeleteWatchlistAction,

  UserAlarmsAction,UserAddAlarmsAction,UserDeleteAlarmsAction,
  UserAddCommentAction,UserAddLikeAction,UserAdddisLikeAction,UserReportCommentAction

} from "./userFinancialAction";

export const UserWatchlistSlice = createSlice({
  name: "UserWatchlistData",
  initialState: {
    UserWatchlistLoading: false,
    UserWatchlist: [],
    UserWatchlistError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch UserWatchlistAction
    builder.addCase(UserWatchlistAction.pending, (state, action) => {
      state.UserWatchlistLoading = true;
    });
    builder.addCase(UserWatchlistAction.fulfilled, (state, action) => {
      state.UserWatchlistLoading = false;
      state.UserWatchlist = action.payload;
      state.UserWatchlistError = null;
    });
    builder.addCase(UserWatchlistAction.rejected, (state, action) => {
      state.UserWatchlistLoading = false;
      state.UserWatchlistError = action.error.message;
    });
  },
});
//#endregion

export const UserAddWatchlistSlice = createSlice({
  name: "UserAddWatchlistData",
  initialState: {
    UserAddWatchlistLoading: false,
    UserAddWatchlist: [],
    UserAddWatchlistError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch UserAddWatchlistAction
    builder.addCase(UserAddWatchlistAction.pending, (state, action) => {
      state.UserAddWatchlistLoading = true;
    });
    builder.addCase(UserAddWatchlistAction.fulfilled, (state, action) => {
      state.UserAddWatchlistLoading = false;
      state.UserAddWatchlist = action.payload;
      state.UserAddWatchlistError = null;
    });
    builder.addCase(UserAddWatchlistAction.rejected, (state, action) => {
      state.UserAddWatchlistLoading = false;
      state.UserAddWatchlistError = action.error.message;
    });
  },
});
//#endregion


export const UserDeleteWatchlistSlice = createSlice({
  name: "UserDeleteWatchlistData",
  initialState: {
    UserDeleteWatchlistLoading: false,
    UserDeleteWatchlist: [],
    UserDeleteWatchlistError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // fetch UserDeleteWatchlistAction
    builder.addCase(UserDeleteWatchlistAction.pending, (state, action) => {
      state.UserDeleteWatchlistLoading = true;
    });
    builder.addCase(UserDeleteWatchlistAction.fulfilled, (state, action) => {
      state.UserDeleteWatchlistLoading = false;
      state.UserDeleteWatchlist = action.payload;
      state.UserDeleteWatchlistError = null;
    });
    builder.addCase(UserDeleteWatchlistAction.rejected, (state, action) => {
      state.UserDeleteWatchlistLoading = false;
      state.UserDeleteWatchlistError = action.error.message;
    });
  },
});
//#endregion







export const UserSingleWatchlistSlice = createSlice({
    name: "UserSingleWatchlistData",
    initialState: {
      UserSingleWatchlistLoading: false,
      UserSingleWatchlist: [],
      UserSingleWatchlistError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      // fetch UserSingleWatchlistAction
      builder.addCase(UserSingleWatchlistAction.pending, (state, action) => {
        state.UserSingleWatchlistLoading = true;
      });
      builder.addCase(UserSingleWatchlistAction.fulfilled, (state, action) => {
        state.UserSingleWatchlistLoading = false;
        state.UserSingleWatchlist = action.payload;
        state.UserSingleWatchlistError = null;
      });
      builder.addCase(UserSingleWatchlistAction.rejected, (state, action) => {
        state.UserSingleWatchlistLoading = false;
        state.UserSingleWatchlistError = action.error.message;
      });
    },
  });




  
  export const UserAddstockWatchlistSlice = createSlice({
    name: "UserAddstockWatchlistData",
    initialState: {
      UserAddstockWatchlistLoading: false,
      UserAddstockWatchlist: [],
      UserAddstockWatchlistError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      // fetch UserAddstockWatchlistAction
      builder.addCase(UserAddstockWatchlistAction.pending, (state, action) => {
        state.UserAddstockWatchlistLoading = true;
      });
      builder.addCase(UserAddstockWatchlistAction.fulfilled, (state, action) => {
        state.UserAddstockWatchlistLoading = false;
        state.UserAddstockWatchlist = action.payload;
        state.UserAddstockWatchlistError = null;
      });
      builder.addCase(UserAddstockWatchlistAction.rejected, (state, action) => {
        state.UserAddstockWatchlistLoading = false;
        state.UserAddstockWatchlistError = action.error.message;
      });
    },
  });
  //#endregion
  


















  export const UserDeletestockWatchlistSlice = createSlice({
    name: "UserDeletestockWatchlistData",
    initialState: {
      UserDeletestockWatchlistLoading: false,
      UserDeletestockWatchlist: [],
      UserDeletestockWatchlistError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      // fetch UserDeletestockWatchlistAction
      builder.addCase(UserDeletestockWatchlistAction.pending, (state, action) => {
        state.UserDeletestockWatchlistLoading = true;
      });
      builder.addCase(UserDeletestockWatchlistAction.fulfilled, (state, action) => {
        state.UserDeletestockWatchlistLoading = false;
        state.UserDeletestockWatchlist = action.payload;
        state.UserDeletestockWatchlistError = null;
      });
      builder.addCase(UserDeletestockWatchlistAction.rejected, (state, action) => {
        state.UserDeletestockWatchlistLoading = false;
        state.UserDeletestockWatchlistError = action.error.message;
      });
    },
  });
  //#endregion
  






































  export const UserAlarmsSlice = createSlice({
    name: "UserAlarmsData",
    initialState: {
      UserAlarmsLoading: false,
      UserAlarms: [],
      UserAlarmsError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      // fetch UserAlarmsAction
      builder.addCase(UserAlarmsAction.pending, (state, action) => {
        state.UserAlarmsLoading = true;
      });
      builder.addCase(UserAlarmsAction.fulfilled, (state, action) => {
        state.UserAlarmsLoading = false;
        state.UserAlarms = action.payload;
        state.UserAlarmsError = null;
      });
      builder.addCase(UserAlarmsAction.rejected, (state, action) => {
        state.UserAlarmsLoading = false;
        state.UserAlarmsError = action.error.message;
      });
    },
  });
  //#endregion
  





  export const UserAddAlarmsSlice = createSlice({
    name: "UserAddAlarmsData",
    initialState: {
      UserAddAlarmsLoading: false,
      UserAddAlarms: [],
      UserAddAlarmsError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      // fetch UserAddAlarmsAction
      builder.addCase(UserAddAlarmsAction.pending, (state, action) => {
        state.UserAddAlarmsLoading = true;
      });
      builder.addCase(UserAddAlarmsAction.fulfilled, (state, action) => {
        state.UserAddAlarmsLoading = false;
        state.UserAddAlarms = action.payload;
        state.UserAddAlarmsError = null;
      });
      builder.addCase(UserAddAlarmsAction.rejected, (state, action) => {
        state.UserAddAlarmsLoading = false;
        state.UserAddAlarmsError = action.error.message;
      });
    },
  });
  //#endregion
  







  export const UserDeleteAlarmsSlice = createSlice({
    name: "UserDeleteAlarmsData",
    initialState: {
      UserDeleteAlarmsLoading: false,
      UserDeleteAlarms: [],
      UserDeleteAlarmsError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      // fetch UserDeleteAlarmsAction
      builder.addCase(UserDeleteAlarmsAction.pending, (state, action) => {
        state.UserDeleteAlarmsLoading = true;
      });
      builder.addCase(UserDeleteAlarmsAction.fulfilled, (state, action) => {
        state.UserDeleteAlarmsLoading = false;
        state.UserDeleteAlarms = action.payload;
        state.UserDeleteAlarmsError = null;
      });
      builder.addCase(UserDeleteAlarmsAction.rejected, (state, action) => {
        state.UserDeleteAlarmsLoading = false;
        state.UserDeleteAlarmsError = action.error.message;
      });
    },
  });
  //#endregion
  











































  export const UserAddCommentSlice = createSlice({
    name: "UserAddCommentData",
    initialState: {
      UserAddCommentLoading: false,
      UserAddComment: [],
      UserAddCommentError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      // fetch UserAddCommentAction
      builder.addCase(UserAddCommentAction.pending, (state, action) => {
        state.UserAddCommentLoading = true;
      });
      builder.addCase(UserAddCommentAction.fulfilled, (state, action) => {
        state.UserAddCommentLoading = false;
        state.UserAddComment = action.payload;
        state.UserAddCommentError = null;
      });
      builder.addCase(UserAddCommentAction.rejected, (state, action) => {
        state.UserAddCommentLoading = false;
        state.UserAddCommentError = action.error.message;
      });
    },
  });
  //#endregion
  













  export const UserAddLikeSlice = createSlice({
    name: "UserAddLikeData",
    initialState: {
      UserAddLikeLoading: false,
      UserAddLike: [],
      UserAddLikeError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      // fetch UserAddLikeAction
      builder.addCase(UserAddLikeAction.pending, (state, action) => {
        state.UserAddLikeLoading = true;
      });
      builder.addCase(UserAddLikeAction.fulfilled, (state, action) => {
        state.UserAddLikeLoading = false;
        state.UserAddLike = action.payload;
        state.UserAddLikeError = null;
      });
      builder.addCase(UserAddLikeAction.rejected, (state, action) => {
        state.UserAddLikeLoading = false;
        state.UserAddLikeError = action.error.message;
      });
    },
  });
  //#endregion

  







  export const UserAdddisLikeSlice = createSlice({
    name: "UserAdddisLikeData",
    initialState: {
      UserAdddisLikeLoading: false,
      UserAdddisLike: [],
      UserAdddisLikeError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      // fetch UserAdddisLikeAction
      builder.addCase(UserAdddisLikeAction.pending, (state, action) => {
        state.UserAdddisLikeLoading = true;
      });
      builder.addCase(UserAdddisLikeAction.fulfilled, (state, action) => {
        state.UserAdddisLikeLoading = false;
        state.UserAdddisLike = action.payload;
        state.UserAdddisLikeError = null;
      });
      builder.addCase(UserAdddisLikeAction.rejected, (state, action) => {
        state.UserAdddisLikeLoading = false;
        state.UserAdddisLikeError = action.error.message;
      });
    },
  });
  //#endregion
  



















  export const UserReportCommentSlice = createSlice({
    name: "UserReportCommentData",
    initialState: {
      UserReportCommentLoading: false,
      UserReportComment: [],
      UserReportCommentError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      // fetch UserReportCommentAction
      builder.addCase(UserReportCommentAction.pending, (state, action) => {
        state.UserReportCommentLoading = true;
      });
      builder.addCase(UserReportCommentAction.fulfilled, (state, action) => {
        state.UserReportCommentLoading = false;
        state.UserReportComment = action.payload;
        state.UserReportCommentError = null;
      });
      builder.addCase(UserReportCommentAction.rejected, (state, action) => {
        state.UserReportCommentLoading = false;
        state.UserReportCommentError = action.error.message;
      });
    },
  });
  //#endregion
  