import { useState, React, useEffect, useRef } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { sidebarAction } from "../../../redux/bourseStockRedux/mainPage/bourseStockMainPageAction";
import { useDispatch, useSelector } from "react-redux";

import {
  CalendarMonthRounded,
  AssessmentRounded,
  BalanceRounded,
  HomeWorkRounded,
  MonetizationOnRounded,
  QueryStatsRounded,
  PieChartRounded,
  Inventory2Rounded,
  CurrencyExchangeRounded,
  StackedLineChartRounded,
} from "@mui/icons-material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import PsychologyIcon from "@mui/icons-material/Psychology";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import PatternIcon from "@mui/icons-material/Pattern";
import ArticleIcon from "@mui/icons-material/Article";
import InfoIcon from "@mui/icons-material/Info";
import CommentIcon from "@mui/icons-material/Comment";
import "react-pro-sidebar/dist/css/styles.css";

import Error from "../../common/Error";
import Loader from "../../common/Loader";

const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      style={{ textAlign: "right", fontSize: 14 }}
      active={selected === title}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      {title}
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ color }) => {
  if (color == "black") {
    var background = "linear-gradient(135deg, #2D1F3D 0%, #121212 100%)";
    var color_hover = "#868dfb !important";
    var color_inner = "";
    var color_active = "#6870fa !important";
    var main_color = "";
  } else if (color == "white") {
    var background = "linear-gradient(135deg, #2D1F3D 0%, #121212 100%)";
    var color_hover = "#868dfb !important";
    var color_inner = "";
    var color_active = "#6870fa !important";
    var main_color = "";
  }

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("سهام");

  const slug = useParams();

  const handleMouseOver = (e) => {
    setIsCollapsed(false);
  };

  console.log(slug.Commodities_slug);

  return (
    <aside
      onMouseOver={handleMouseOver}
      onMouseOut={() => setIsCollapsed(true)}
      style={{ backgroundColor: { background }, minHeight: "94vh" }}
    >
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: background,
            color: main_color,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
            color: color_inner,
          },
          "& .pro-inner-item": {
            padding: "1px 35px 2px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: color_hover,
          },
          "& .pro-menu-item.active": {
            color: color_active,
          },
          direction:"rtl"
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}

            <div style={{minHeight:"94vh"}}>
              <>
                <Item
                  title="Commodity"
                  to={`/Commodities/${slug.Commodities_slug}`}
                  icon={<HomeOutlinedIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>

              <hr />
              <SubMenu
                defaultOpen="true"
                title="indicators"
                style={{ textAlign: "right" }}
              >
                <hr />
                <Item
                  title="RSI"
                  to={`/Commodities/${slug.Commodities_slug}/RSI`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="MFI"
                  to={`/Commodities/${slug.Commodities_slug}/MFI`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="EMA"
                  to={`/Commodities/${slug.Commodities_slug}/EMA`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="MACD"
                  to={`/Commodities/${slug.Commodities_slug}/MACD`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Standard deviation"
                  to={`/Commodities/${slug.Commodities_slug}/Standard_deviation`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Stochastic oscillator"
                  to={`/Commodities/${slug.Commodities_slug}/Stochastic_oscillator`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Bollinger Bands"
                  to={`/Commodities/${slug.Commodities_slug}/Bollinger_Bands`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Ichimoku"
                  to={`/Commodities/${slug.Commodities_slug}/Ichimoku`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Fibonacci"
                  to={`/Commodities/${slug.Commodities_slug}/Fibonacci`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Volatility"
                  to={`/Commodities/${slug.Commodities_slug}/Volatility`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Parabolic SAR"
                  to={`/Commodities/${slug.Commodities_slug}/Parabolic_SAR`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Aroon"
                  to={`/Commodities/${slug.Commodities_slug}/Aroon`}
                  icon={<CandlestickChartIcon className="ms-1" />}
                  selected={selected}
                  setSelected={setSelected}
                />

      

     
              </SubMenu>
              <hr />
            </div>
          </Menu>
        </ProSidebar>
      </Box>
    </aside>
  );
};

export default Sidebar;
